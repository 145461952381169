.collapse-wrapper {
  .collapse-header {
    position: relative;
    .btn-link {
      &:before {
        content: '\f0da';
        /* >> Name of the FA free font (mandatory), e.g.:
                   - 'Font Awesome 5 Free' for Regular and Solid symbols;
                   - 'Font Awesome 5 Pro' for Regular and Solid symbols (Professional License);
                   - 'Font Awesome 5 Brand' for Brands symbols. */
        font-family: 'Font Awesome 5 Free';
        /* >> Weight of the font (mandatory):
                   - 400 for Regular and Brands symbols;
                   - 900 for Solid symbols;
                   - 300 for Light symbols. */
        font-weight: 900;

        transition: all 0.5s;
        position: absolute;
        left: 0;
      }
      &.active {
        &:before {
          -webkit-transform: rotate(90deg);
          -moz-transform: rotate(90deg);
          transform: rotate(90deg);
        }
      }
    }
  }
}
