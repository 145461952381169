/* Dashboard */
.dashboard-page {
  margin: 0 50px;
  .sub-heading {
    margin-bottom: 1.825em;
  }
  .btn {
    padding: 0.75rem 3rem;
  }
  .form-group {
    .form-control {
      height: 45px !important;
    }
  }
}

.product-table {
  button {
    background-color: transparent;
    border: none;
    &:hover {
      background: #ffffff;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
      border-radius: 50px;
    }
  }
}

.container-member-img {
  position: relative;
  .avatar-detail-profile {
    background: #7f3f98;
    width: 37px;
    height: 37px;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    .text-initial {
      color: #fff;
      font-size: 12px;
    }
  }
  .member-img {
    border-radius: 50%;
  }
  .bullet-icon-img {
    border-radius: 50%;
    width: 12px;
    height: 12px;
    border: 2px solid #fff;
    position: absolute;
    bottom: -15px;
    right: -2px;
  }
}

.shopping-detail {
  .ant-btn {
    box-shadow: none;
    font-size: 11px;
    font-weight: bold;
    &:hover {
      color: #4f4f4f;
    }
    &:focus {
      color: #4f4f4f;
    }
  }
}
.shopping-detail-content {
  .row {
    .col-4 {
      font-size: 11px;
      margin-bottom: 5px;
    }
  }
  hr {
    margin: 12px 0;
  }
  hr:last-child {
    margin-top: 0;
    color: red;
  }
  .shopping-detail-title {
    font-size: 11px;
    color: #bdbdbd;
    font-weight: bold;
  }
}

// Media
.border-product {
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  // margin: 10px 0;
  padding: 20px 0;
  width: 200px;
  height: 200px;
  img {
    object-fit: contain;
    width: 100%;
    max-height: 100%;
  }
}
.detail-modal-media {
  font-size: 12px;
}
.ant-checkbox-group {
  .row {
    .product-wrapper {
      display: flex;
      position: relative;
      border: 1px solid #e0e0e0;
      border-radius: 10px;
      margin: 10px;
      padding: 10px;
      width: 150px;
      height: 150px;
      .ant-checkbox-wrapper {
        position: absolute;
        .ant-checkbox {
          &:hover {
            border-color: #544397;
          }
          .ant-checkbox-inner {
            width: 25px;
            height: 25px;
            border-radius: 50%;
            border-color: none;
            &::after {
              left: 35%;
            }
            &:hover {
              border-color: #544397 !important;
            }
          }
        }
        .ant-checkbox-checked .ant-checkbox-inner {
          border: none !important;
          background-color: #544397 !important;
        }
      }
      img {
        cursor: pointer;
        object-fit: contain;
        width: 100%;
        max-height: 100%;
      }
    }
  }
}

.background-default {
  background: $sidely-bg;
}
.background-bar {
  background: #ffffff;
  // box-shadow: 0px 0px 3px rgba(37, 65, 83, 0.25);
  padding: 15px;
}
.background-form {
  background: #ffffff;
  // box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.03);
  border-radius: 4px;
  padding: 10px 0px;
  height: auto;

  &.full-form {
    margin-top: 7rem;
  }

  .card-title {
    padding: 25px 65px;
  }
  .form-group {
    margin: auto;
    padding-bottom: 20px;
    .col-md-2 {
      text-align: right;
    }
  }
}
