.detail-data-card {
  .detail-list {
    padding: 5px 0;
    display: flex;
    flex-wrap: wrap;
    .detail-key {
      // font-weight: bold;
      font-size: 12px;
    }
    .detail-value {
      color: #5c5c5c;
      font-size: 12px;
    }
  }
}

.link-detail {
  color: #4f4f4f;
}

.link-detail:hover {
  border-bottom: none;
  text-decoration: none;
  color: #7f3f98;
}

.detail-title {
  color: #5c5c5c;
  font-size: 14px;
  margin-left: 10px;
}

.check-margin {
  margin-right: 20px;
  margin-left: 10px;
}

.card .borderCard {
  border: 1px solid #000000 !important;
  border-radius: 5px;
  padding: 1rem 1rem;
}

.box-button {
  margin-left: 10px;
}

.box-left-trans {
  display: flex;
  flex-wrap: wrap;
}

.form-input-color {
  background-color: #e5e5e5;
  border-color: #e5e5e5;
  padding-bottom: 5px;
  padding-top: 5px;
  border-radius: 5px;
}

.box-status {
  background-color: #225cb1;
  margin-left: 10px;
  color: white;
  padding: 5px;
  font-size: 12px;
  border-radius: 5px;
}

.text-timeline {
  margin-bottom: 2px !important;
  font-weight: 600;
}

.button-status {
  padding: 8px;
  color: #ffffff;
  border-radius: 5px;
  text-align: center;
}

.ant-timeline-item-head-blue {
  color: #004e87 !important;
  border-color: #004e87 !important;
  background-color: #004e87 !important;
  width: 15px !important;
  height: 15px !important;
}

.ant-timeline-item-tail {
  left: 7px !important;
}

.ant-timeline-item-content {
  margin: 0 0 0 25px !important;
}

.dropdown-custom {
  font-size: 12px;
  color: #5c5c5c;
  width: 100% !important;
}

.dropdown-custom-menu {
  width: 100% !important;
}

.button-addpic {
  width: 30%;
  border-radius: 3px;
  background-color: #5a7792;
  border-color: #5a7792;
  border-width: 1px;
  color: #ffffff;
}

.box-form-array {
  background-color: #f7f7f8;
  padding: 10px;
  border-bottom: solid 1px #d8d8d8;
  height: 110px;
  position: relative;
}

.button-close-array {
  position: absolute;
  top: 5px;
  right: 5px;
  color: #277ed2;
  border-color: #277ed2;
  font-size: 0.7rem;
}

.text-satuan {
  padding: 25px;
  margin-left: -30px;
}

.tab-custom-input {
  background-color: #f0f0f0;
  border-color: #dcdbdc;
  border-width: 1px;
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: 300 !important;
}

.box-checkbox-custom {
  padding-left: 20px;
}

.tab-custom-input .nav-link.active {
  padding: 10px 20px;
  background-color: #65829d !important;
}

.box-tab {
  display: flex;
  flex-wrap: wrap;
  border: solid 1px #d5d4d5;
  margin-bottom: 10px;
  margin-top: -10px;
  padding-top: 10px;
}

.text-label-custom {
  margin-bottom: 10px;
}

.title-user-transaction {
  margin-left: 10px;
  font-weight: 600;
}

.savetopdf-button {
  height: 40px;
}

.button-add-listing {
  width: 30%;
  border-radius: 3px;
  background-color: #5a7792;
  border-color: #5a7792;
  border-width: 1px;
  color: #ffffff;
  padding: 5px;
  text-align: center;
}

.button-add-listing > a {
  color: #ffffff !important;
}

.button-add-listing:hover {
  background-color: #5a7792b0;
  border-color: #5a7792b0;
}

.border-end-table {
  border-bottom: solid 1px #000000;
  margin: 0px;
  padding-bottom: 10px;
  margin-bottom: 30px;
}

.green-bullet {
  background-color: #00b140;
  width: 6px;
  height: 6px;
  border-radius: 3px;
  margin: 2px;
  margin-right: 5px;
}

.green-text {
  color: #00b140;
}

.grey-bullet {
  background-color: #bdbdbd;
  width: 6px;
  height: 6px;
  border-radius: 3px;
  margin: 2px;
  margin-right: 5px;
}

.grey-text {
  color: #bdbdbd;
}

.blue-bullet {
  background-color: #2d9cdb;
  width: 6px;
  height: 6px;
  border-radius: 3px;
  margin: 2px;
  margin-right: 5px;
}

.blue-text {
  color: #2d9cdb;
}

.flag-icon {
  width: 20px !important;
  height: 20px !important;
}

.image-table-list {
  height: auto;
}
.customer-title {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

.custom-scroll {
  // height: 81vh;
}

.custom-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #ffffff;
}

.custom-scroll::-webkit-scrollbar {
  // &.mixt-scroll {
  //   width: 8px;
  // }

  // &.select-scroll {
  //   width: 12px;
  // }
  width: 10px;
  background-color: #ffffff;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background-color: $sidely-blue;
  border-radius: 10px;
  // background-image: -webkit-linear-gradient(
  //   45deg,
  //   rgba(255, 255, 255, 0.2) 25%,
  //   transparent 25%,
  //   transparent 50%,
  //   rgba(255, 255, 255, 0.2) 50%,
  //   rgba(255, 255, 255, 0.2) 75%,
  //   transparent 75%,
  //   transparent
  // );
}

.detail {
  .card {
    border-radius: 0px 0px 4px 4px;
  }
  .info {
    background: #eaeaea;
    border-radius: 4px 4px 0px 0px;
    div {
      font-family: Poppins;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.02em;
      color: #ffffff;
    }
    .Prospect {
      background-color: #ffea2c;
      border-radius: 4px 4px 0px 0px;
    }
    .Suspect {
      background-color: $sidely-blue;
      border-radius: 4px 0px 0px 0px;
    }
    .Lead {
      background-color: #ff9345;
      border-radius: 4px 4px 0px 0px;
    }
    .Client {
      background-color: #2ed47a;
      border-radius: 4px 4px 0px 0px;
    }
    .Inactive {
      background-color: #aeaeae;
      border-radius: 0px 4px 0px 0px;
    }
  }
  .name {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 19px;
    line-height: 28px;
    color: #323c47;
  }
  .in-charge div {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.01em;
    color: #90a0b7;
  }
  .profile {
    .col-md-4 {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: rgba(37, 65, 83, 0.8);
    }
    .company {
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #323c47;
    }
    .email {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: $sidely-blue;
    }
    .phone {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #323c47;
    }
    .position {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #323c47;
    }
  }
}
.btn-plus-size {
  font-size: 1.2em !important;
  vertical-align: 1px;
}
