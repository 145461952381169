/* Icons */

.icons-list {
    border-left: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
    >div {
        background: $white;
        border-top: 1px solid $border-color;
        border-right: 1px solid $border-color;
        @include display-flex;
        @include align-items(center);
        padding: 15px;
        font-family: $type-1;
        font-size: $default-font-size;
        i {
            display: inline-block;
            font-size: 20px;
            width: 40px;
            text-align: left;
            color: theme-color(primary);
        }
    }
}

.custom-icon {
    width: 25px;
    height: 25px;
    cursor: pointer;
}

.custom-icon-views {
    width: 24x;
    height: 24px;
    cursor: pointer;
    margin: 0px 2px;
}

.custom-icon-2 {
    width: 30px;
    height: 30px;
}

.custom-icon-3 {
    width: 16px;
    height: auto;
    cursor: pointer;
}

.custom-icon-add-tight {
    width: 12px;
    height: auto;
    cursor: pointer;
}

.custom-btn {
    width: 35px;
    height: 35px;
    background-color: #fff;
    box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.06);
    cursor: pointer;
}

.custom-icon-blue {
    // width: 30px;
    // height: 30px;
    background-color: #e8f8ff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.star-active {
    color: $sidely-blue;
    margin-right: 1px;
}

.star-inactive {
    color: $sidely-blue-blur;
    margin-right: 1px;
}

.pointer-move {
    cursor: url('../../../src/images/ui_icon//cursor_move.svg'), auto;
}

.pinPoint {
    font-size: 10px;
    position: absolute;
    top: 5px;
    // left: 7px;
    color: white;
    font-weight: bold;
}

.icon-field {
    position: absolute;
    right: 20px;
    top: 7px;
}

.custom-clustericon {
    background: red !important;
    color: #fff;
    border-radius: 100%;
    font-weight: bold;
    font-size: 15px;
    display: flex;
    align-items: center;
}

.custom-clustericon::before {
    padding: 7px;
}

.custom-clustericon::after {
    padding: 14px;
}

#map-container {
    padding: 6px;
    border-width: 1px;
    border-style: solid;
    border-color: #ccc #ccc #999 #ccc;
    -webkit-box-shadow: rgba(64, 64, 64, 0.5) 0 2px 5px;
    -moz-box-shadow: rgba(64, 64, 64, 0.5) 0 2px 5px;
    box-shadow: rgba(64, 64, 64, 0.1) 0 2px 5px;
    width: 800px;
}

#map {
    width: 100vw;
    height: 100vh;
}