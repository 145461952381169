// Cards Mixins
@mixin card-inverse-variant($bg, $border, $color) {
  background: $bg;
  border: 1px solid $border;
  color: $color;
}

.tag-detail {
  display: inline-block;
  width: auto;
  border-radius: 5px;
}

.tag-status {
  border: 1px solid #2ed47a;
  border-radius: 5px;
  color: #2ed47a !important;
}

.bgCard {
  border-radius: 4px;
  color: white;
}

.tag-card{
  border: 1px solid;
    border-radius: 4px;
    font-size: 12px;
    padding: 3px 12px;
}
