.news-carousel {
  height: 275px;

  img {
    height: 275px;
  }
}

.news-carousel-detail {
  height: 275px;

  img {
    height: 275px;
  }
}

.my-list-container {
  height: calc(100vh - 3em - 205px);
  max-height: calc(100vh - 3em - 205px);
  overflow-y: scroll;
  background-color: white;
}

.my-list-container::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

.my-list-container::-webkit-scrollbar-thumb {
  background-color: #00b2ff;
}

.my-create-container {
  // height: calc(100vh - 4em - 165px);
  // max-height: calc(100vh - 4em - 165px);
  height: calc(100vh - 8.5em);
  overflow-y: scroll;
  background-color: white;
}

.my-create-container::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

.my-create-container::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
}

.carousel-indicators {
  margin-bottom: 5px;

  li {
    height: 5px !important;
    width: 5px !important;
    border-radius: 5px;
    box-shadow: inset 1px 1px #888888;
  }
}

.list-news {
  border: 0;
  border-top: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  background-color: white;
  text-align: left;
}

.carousel-caption {
  left: 5%;
  right: 5%;
  bottom: 0;
}

.news-container {
  height: calc(100vh - 5em);
  max-height: calc(100vh - 5em);
  overflow-y: auto;
  background-color: white;
  padding: 1em;
}

.news-container::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

.news-container::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
}

.comment-line {
  height: 3px;
  background-color: #eaeaea;
  width: 100%;
}

.avatar-comment {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

.container-uploader {
  max-width: 100%;
  width: 100%;
  overflow-x: auto;
  display: flex;
  flex-wrap: nowrap;
}

.container-uploader::-webkit-scrollbar {
  height: 5px;
  background-color: #f5f5f5;
}

.container-uploader::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
}

.border-50 {
  .container-dropzone {
    .ant-upload {
      &.ant-upload-select,
      .ant-upload-image {
        border-radius: 50%;
      }
    }
  }
}

.container-dropzone {
  margin: 0.5rem;
  .image-group-selector {
    border-radius: 5px;
  }
  .ant-upload {
    &.ant-upload-select {
      width: 140px;
      height: 140px;
      background: #e3f6ff;
      border: 2px dashed #00b2ff;
      margin: 0;
    }
    .anticon {
      font-size: 35px;
    }
    .ant-upload-text {
      color: #000000;
      font-size: 0.8rem;
      margin-top: 0.5rem;
    }
    .ant-upload-image {
      max-width: 120px;
      max-height: 120px;
      width: 100%;
    }
  }
}

.user-group-container {
  background-color: white;
  height: calc(100vh - 100px - 4em);
  max-height: calc(100vh - 100px - 4em);
  padding: 10px !important;
  overflow-y: auto;
}

.news-content-container {
  background-color: white;
  height: calc(100vh - 100px - 4em);
  max-height: calc(100vh - 100px - 4em);
  padding: 10px !important;
  overflow-y: auto;
}

.form-control[readonly] {
  background-color: white;
  border-color: #f2f2f2;
}

.team-container {
  margin-top: 4em;
  height: calc(100vh - 7em);
  .box-wrapper {
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    margin: 0 1rem;
    background-color: #ffffff;
  }
}

.left-team-container {
  height: calc(100vh - 10.5em);
}

.right-team-container {
  height: calc(100vh - 4em);
}

.file-label {
  width: 100%;
  padding: 35px 0;
  background-color: #c1c1c1;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
}

.img-list-news {
  width: 100%;
  height: 70px;
  min-height: 70px;
}

.badge-input-container {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  width: 100%;
}

.badge-input-container::-webkit-scrollbar {
  height: 5px;
  background-color: #f5f5f5;
}

.badge-input-container::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
}

.react-date-picker__wrapper {
  border: 0;
}

svg[Attributes] {
  width: 19;
  height: 19;
  stroke: gray;
  stroke-width: 2;
}

img.avatar {
  border-radius: 50%;
  height: 40px;
  width: 40px;
}

.carousel-indicators .active {
  background-color: #00b2ff;
}

.carousel-indicators li {
  background-color: white;
  height: 7px !important;
  width: 7px !important;
}

.f-12 {
  font-size: 12px !important;
  line-height: 14px !important;
}

.carousel-indicators li {
  border-radius: 50% !important;
  background-color: white;
}

.mw-75 {
  max-width: 75% !important;
}

.mw-65 {
  max-width: 65% !important;
}

.bg-none {
  background-color: transparent !important;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 30px;
  height: 30px;
}
