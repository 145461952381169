.lds-dual-ring {
  display: inline-block;
  width: auto;
  height: auto;
}
.lds-dual-ring:after {
  content: ' ';
  display: block;
  width: 20px;
  height: 20px;
  margin: 1px;
  border-radius: 50%;
  border: 2px solid #fff;
  border-color: $sidely-blue transparent $sidely-blue transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

.dual-ring-dark {
  display: inline-block;
  width: 30px;
  height: 15px;
}
.dual-ring-dark:after {
  content: ' ';
  display: block;
  width: 30px;
  height: 30px;
  margin: 1px;
  border-radius: 50%;
  border: 3px solid #fff;
  border-color: #4f4f4f transparent #4f4f4f transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

.dual-ring-white {
  display: inline-block;
  width: 30px;
  height: 15px;
}
.dual-ring-white:after {
  content: ' ';
  display: block;
  width: 30px;
  height: 30px;
  margin: 1px;
  border-radius: 50%;
  border: 3px solid #fff;
  border-color: #ffff transparent #ffff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dual-ring-dark {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader-content {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  height: 50px;
  width: 50px;
  // background: rgba(170, 168, 168, 0.28);
  // background: white;
  background-color: transparent;
  .lds-dual-ring {
    margin-top: 10px;
    margin-left: 5px;
  }
}

.loader-content-dark {
  position: absolute;
  top: 4.5rem;
  right: 15.5rem;
}

.btn-light-cs {
  position: relative;
  .lds-dual-ring {
    position: absolute;
    left: 5px;
    top: 5px;
    &:after {
      width: 15px;
      height: 15px;
      border-color: #828282 transparent #828282 transparent;
    }
  }
}

.loading-permission {
  position: absolute;
  background: rgba(0, 0, 0, 0.17);
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9;
  .lds-dual-ring {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 100px;
  }
}

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 99999;
}
