/* Typography */

:root,
body {
  font-size: 1rem;
  font-family: $type-1;
  color: $default-color;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.title,
.sub-heading,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $type-1;
  font-weight: bold;
  color: #333;
}

p {
  font-size: $default-font-size;
}

.h1,
h1 {
  font-size: 24px;
  line-height: 29px;
}

.h2,
h2 {
  font-size: 21px;
  line-height: 26px;
}

.h3,
h3 {
  font-size: 18px;
  line-height: 22px;
}

.h4,
h4 {
  font-size: 14px;
  line-height: 16px;
}

.h5,
h5 {
  font-size: 14px;
  line-height: 16px;
}

.h6,
h6 {
  font-size: 12px;
  line-height: 14px;
}

.title {
  font-size: 36px;
  line-height: 44px;
}

.sub-heading {
  font-size: 21px;
  line-height: 26px;
}

p {
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  color: #333;
}

strong {
  font-weight: bold;
}

.text-tab {
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  color: #e0e0e0 !important;

  &.active {
    color: #7f3f98 !important;
  }
}

.display-1 {
  font-size: 3.75rem;

  @media (max-width: 991px) {
    font-size: 3rem;
  }
}

.display-2 {
  font-size: 3.125rem;

  @media (max-width: 991px) {
    font-size: 2.5rem;
  }
}

.display-3 {
  font-size: 2.5rem;

  @media (max-width: 991px) {
    font-size: 2rem;
  }
}

.display-4 {
  font-size: 1.875rem;

  @media (max-width: 991px) {
    font-size: 1.5rem;
  }
}

.display-5 {
  font-size: 1.25rem;

  @media (max-width: 991px) {
    font-size: 1rem;
  }
}

.blockquote {
  padding: 1.25rem;
  border: 1px solid $border-color;
}

address {
  p {
    margin-bottom: 0;
  }
}

//blockqoute color variations
@each $color, $value in $theme-colors {
  .blockquote-#{$color} {
    @include blockquote($value);
  }
}

.page-title {
  color: $black;
  margin: 0.38rem 0 0.75rem;
}

.textLimitEvent {
  white-space: nowrap;
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 0.82rem;
}

.titleEvent {
  white-space: nowrap;
  width: 8rem;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 0.82rem;
}

.card-title {
  font-family: $type-2;
  font-weight: 500;
  font-style: normal;
  // line-height: 25px;
  color: #324c47;
  margin-bottom: 22px;
  font-size: 12px;
  text-transform: capitalize;

  .rtl & {
    text-align: right;
  }
}

.param-title {
  font-family: $type-2;
  font-weight: 500;
  font-style: normal;
  // line-height: 25px;
  color: #324c47;
  margin-bottom: 22px;
  font-size: 14px;
  padding: 25px 65px;

  .rtl & {
    text-align: right;
  }
}

.param-field {
  font-family: $type-2;
  margin-left: 70px;
  font-size: 12px;
}

.card-subtitle {
  @extend .text-gray;
  font-family: $type-1;
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}

.card-description {
  margin-bottom: 0.9375rem;
  font-family: $type-1;

  .rtl & {
    text-align: right;
  }
}

.font-size-10 {
  font-size: 10px;
}

.font-size-11 {
  font-size: 11px;
}

.font-size-12 {
  font-size: 12px;
}

.font-size-13 {
  font-size: 13px;
}

.font-size-14 {
  font-size: 14px;
}

.font-size-15 {
  font-size: 15px;
}

.font-size-16 {
  font-size: 16px;
}

.font-size-17 {
  font-size: 17px;
}

.font-size-18 {
  font-size: 18px;
}

.line-height-15 {
  line-height: 15px;
}

.line-height-16 {
  line-height: 16px;
}

.line-height-17 {
  line-height: 17px;
}

.line-height-18 {
  line-height: 18px;
}

.line-height-19 {
  line-height: 19px;
}

.line-height-20 {
  line-height: 20px;
}

.line-height-21 {
  line-height: 21px;
}

.line-height-22 {
  line-height: 22px;
}

.line-height-23 {
  line-height: 23px;
}

.line-height-24 {
  line-height: 24px;
}

.line-height-25 {
  line-height: 25px;
}

.line-height-26 {
  line-height: 26px;
}

.font-style-normal {
  font-style: normal;
}

.font-style-bold {
  font-style: bold;
}

.font-weight-normal {
  font-weight: normal;
}

.font-weight-450 {
  font-weight: 450;
}

.font-weight-medium {
  font-weight: 500;
}

.font-weight-semibold {
  font-weight: 600;
}

.spacing-1 {
  letter-spacing: 0.01em;
}

.spacing-2 {
  letter-spacing: 0.02em;
}

.spacing-3 {
  letter-spacing: 0.03em;
}

.spacing-4 {
  letter-spacing: 0.04em;
}

.spacing-5 {
  letter-spacing: 0.05em;
}

small,
.text-small {
  font-size: 11px;
}

.icon-lg {
  font-size: 2.5rem;
}

.icon-md {
  font-size: 1.875rem;
}

.icon-sm {
  font-size: 1rem;
}

// text
.text-blue {
  color: $blue;
}

.text-purple {
  color: $purple;
}

.text-yellow {
  color: $yellow;
}

.text-green {
  color: $green;
}

.text-red {
  color: $red;
}

.text-placeholder {
  color: #cdcdcd !important;
  font-weight: 300 !important;
  
  line-height: 14px !important;
  padding: 0 !important;
  height: 30px !important;
  border: 0px !important;
}

.text-value {
  color: black !important;
  font-weight: 300 !important;
}

.custom-select-input {
  border: none;
  font-size: 12px;
  width: 100%;
  line-height: 1px;
}

.text-save {
  font-weight: 600;
  font-size: 13px;
  letter-spacing: 0.01em;
  color: #ffffff;
}

.text-save-formbuilder {
  font-weight: 600;
  font-size: 13px;
  letter-spacing: 0.01em;
  color: $sidely-blue;
}

.circle-green {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: #00b140;
  position: absolute;
  right: 20px;
  top: 30px;
  padding: 4px;
}

.form-control-email {
  border: 1px solid #efefef;
  font-size: 0.75rem;
  padding: 1px 1px;
  line-height: 14px;
  font-weight: 300;
  border-radius: 5px;
}

.form-control-subEmail {
  border: none;
  font-size: 0.75rem;
  padding: 0.56rem 0.75rem;
  line-height: 14px;
  font-weight: 300;
  width: 100%;
}

.nav-custom {
  .nav-link {
    font-weight: 500;
    color: #323c47;
    line-height: 16px;

    &.active {
      color: $sidely-blue;
      border-left: 8px solid;
    }

    &:hover {
      color: $sidely-blue !important;
    }
  }
}

.type-link {
  cursor: pointer;
  color: #00b2ff !important;
}

.detail-link {
  color: #323c47 !important;
}

.word-break {
  word-break: break-all;
}

.opportunity-head {
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 20px;
  letter-spacing: 0.04em;
}

.opportunity-body-1 {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;
  letter-spacing: 0.02em;
}

.opportunity-body-2 {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 24px;
  letter-spacing: 0.02em;
}

.opportunity-badge-text {
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 13px;
  letter-spacing: 0.01em;
  color: #ffffff;
}

.probability-text {
  .flex-fill {
    font-weight: bold;
    font-size: 12px;
    color: #ffffff;
  }
}

.card-head {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  // line-height: 18px;
  letter-spacing: 0.01em;
  color: #4c5862;
  // height: 40px;
  word-break: break-all;
}

.card-text-1 {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  // line-height: 16px;
  letter-spacing: 0.01em;
  color: rgba(76, 88, 98, 0.5);
}

.card-text-2 {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  // line-height: 18px;
  letter-spacing: 0.01em;
  color: #707683;
}

.card-text-3 {
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  // line-height: 14px;
  letter-spacing: 0.01em;
  color: #707683;
}

.tx-0 {
  color: $opportunity-0 !important;
  font-size: 14px !important;
}

.tx-1 {
  color: $opportunity-10 !important;
  font-size: 14px !important;
}

.tx-2 {
  color: $opportunity-20 !important;
  font-size: 14px !important;
}

.tx-3 {
  color: $opportunity-30 !important;
  font-size: 14px !important;
}

.tx-4 {
  color: $opportunity-40 !important;
  font-size: 14px !important;
}

.tx-5 {
  color: $opportunity-50 !important;
  font-size: 14px !important;
}

.tx-6 {
  color: $opportunity-60 !important;
  font-size: 14px !important;
}

.tx-7 {
  color: $opportunity-70 !important;
  font-size: 14px !important;
}

.tx-8 {
  color: $opportunity-80 !important;
  font-size: 14px !important;
}

.tx-9 {
  color: $opportunity-90 !important;
  font-size: 14px !important;
}

.tx-10 {
  color: $opportunity-100 !important;
  font-size: 14px !important;
}

.section-title {
  color: #323C47
}

.no-transition {
  transition: none;
}