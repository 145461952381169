.modal-footer {
  display: flex;
  align-items: center; // vertically center
  justify-content: center; // Right align buttons with flex property because text-align doesn't work on flex items
  padding: $modal-inner-padding;
  border-top: $modal-footer-border-width solid $modal-footer-border-color;
  @include border-bottom-radius($modal-content-border-radius);

  // Easily place margin between footer elements
  > :not(:first-child) {
    margin-left: 0.25rem;
  }

  > :not(:last-child) {
    margin-right: 0.25rem;
  }
}

.modal-body-view {
  padding: 1.5rem 1rem;
  font-size: 12px;
}

.modal-cs {
  max-width: 440px;

  &.modal-media {
    max-width: 900px;

    .card-body {
      min-height: 400px;

      .dropzone-input-ui {
        .border-dash {
          padding: 60px 50px;
        }
      }

      .media-list {
        .card-body {
          min-height: unset;
        }
      }
    }

    .media-notfound {
      background: #fbfbfb;
      border: 1px solid #e0e0e0;
      border-radius: 5px;
      text-align: center;
      padding: 60px 50px;
    }
  }

  .modal-content {
    border-radius: 10px;
    overflow: hidden;

    .close-btn {
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
      color: #bdbdbd;
    }

    .card-body {
      .card-title {
        font-size: 12px;
        color: #4f4f4f;
        font-weight: bold;
      }
    }
  }
}

.modal-map-header {
  background-color: $sidely-blue;
  border: $sidely-blue;
  display: block;
  padding: 0.5rem;

  h5 {
    color: white;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: center;
    line-height: 13px;
  }
}

.modal-map-body {
  text-align: center;

  h1 {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
  }

  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 0px;
  }
}

.modal-error-validate {
  max-height: 150px;
  overflow-y: auto;
  padding: 0px 40px;
}

.modal-map-list {
  max-height: 160px;
  overflow-y: auto;
  overflow-x: hidden;
}

.btn-modal-search {
  position: fixed;
  top: 50%;
  color: #c7c7c7;
  background-color: #fff;
  border: none;
  filter: drop-shadow(5px 0px 7px rgba(0, 0, 0, 0.24));
}

//
@media (min-width: 700px) {
  .modal-dialog {
    max-width: 600px;
  }
}
