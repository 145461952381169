/* Miscellanoeous */

body,
html {
  overflow-x: hidden;
  padding-right: 0 !important; // resets padding right added by Bootstrap modal
}

*:-moz-full-screen,
*:-webkit-full-screen,
*:fullscreen *:-ms-fullscreen {
  overflow: auto !important;
}

.page-body-wrapper {
  // min-height: calc(100vh - #{$navbar-height});
  // min-height: calc(100vh);
  min-height: calc(10vh);
  @include display-flex();
  @include flex-direction(row);
  padding-left: 0;
  padding-right: 0;

  &:not(.auth-page) {
    // padding-top: $navbar-height;
  }

  &.full-page-wrapper {
    width: 100%;
    min-height: 100vh;
  }
}

.main-panel {
  transition: width $action-transition-duration $action-transition-timing-function,
    margin $action-transition-duration $action-transition-timing-function;
  width: calc(100% - #{$sidebar-width-lg});
  min-height: calc(100vh - #{$navbar-height});
  @include display-flex();
  @include flex-direction(column);

  @media (max-width: 991px) {
    margin-left: 0;
    width: 100%;
  }
}

.content-wrapper {
  // background: $content-bg;
  background: $sidely-bg;
  height: auto;
  // padding: 2rem 3rem;
  width: auto;

  @include flex-grow(1);

  .table-responsive {
    max-width: 98.5%;
    margin: auto;
    background: #ffffff;
    border-radius: 4px;
    margin-top: 4em;
    margin-bottom: 2.3em;
  }
}

.container-scroller {
  overflow: hidden;
}

.scroll-container {
  position: relative;

  &.horizontally {
    overflow-x: hidden;
    width: 100%;
    max-width: 100%;
  }

  &.vertically {
    overflow-y: hidden;
    height: 100%;
    max-height: 100%;
  }
}

pre {
  background: color(gray-lighter);
  padding: 15px;
  font-size: 14px;
}

.image-thumbnail {
  object-fit: cover;
}

.list-loader {
  margin-top: 7.5em;
}

.sticky {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 10;
}

.sticky-v2 {
  position: fixed;
  width: 100%;
  height: 57px;
  top: 50px;
  z-index: 9;
}

.sticky-kanban-status {
  position: fixed;
  width: 100%;
  height: 57px;
  top: 107px;
  z-index: 8;
}

.sticky-v3 {
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 9;
}

.sticky-mixt-view {
  position: fixed;
  width: 14.8%;
  z-index: 1;
  height: calc(100% - 120px) !important;
  z-index: 9;
}

.list-bottom-nav {
  padding-left: 1.5rem !important;
  padding-bottom: 0.75rem !important;
  padding-top: 0.75rem !important;
  background-color: #ffffff !important;
}

.mt-4em {
  margin-top: 4em;
}

.mt-8em {
  margin-top: 8em;
}

.w-16 {
  width: 16.667%;
}

.w10 {
  width: 10% !important;
}

.w5 {
  width: 5% !important;
}

.kanban-wrapper {
  // margin-top: 8em;
  margin-top: 0.5em;
}

.DateRangePicker__CalendarHighlight--single {
  border: 1px solid $sidely-blue !important;
}

.DateRangePicker__CalendarSelection--is-pending {
  background-color: $sidely-blue-blur !important;
  border-width: 0px !important;
}

.DateRangePicker__CalendarSelection {
  background-color: $sidely-blue !important;
  border: 1px solid $sidely-blue !important;
}

.form-control {
  height: 38px;
  border-radius: 5px;
}

.btn-close-form {
  font-size: 25px;
  margin-top: -8px;
}

a:hover {
  text-decoration: none !important;
}

.color-low {
  color: #ffb946;
}

.color-medium {
  color: #ffea2c;
}

.color-high {
  color: #2ed47a;
}

.default-label {
  color: rgba(37, 65, 83, 0.8) !important;
}

.scroll-x::-webkit-scrollbar {
  max-height: 10px;
  background: #f3f3f3 !important;
  border-radius: 5px;
}