.img-progress {
  width: 24px;
  height: 24px;
}

.auto-truncate-1 {
  width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.progress {
  width: 95%;
  border-radius: 20px !important;
  height: 11px !important;
}

.progressBar1 {
  .progress-bar {
    background-color: #2ed47a;
  }

  &.text-percentage {
    color: #2ed47a;
  }
}

.progressBar2 {
  .progress-bar {
    background-color: #4cdc8e;
  }
}

.progressBar3 {
  .progress-bar {
    background-color: #5ee09a;
  }
}

.progressBar4 {
  .progress-bar {
    background-color: #92f8c1;
  }
}

.progressBar5 {
  .progress-bar {
    background-color: #aff5cf;
  }
}

.progress-bar-vertical {
  width: 20px;
  min-height: 370px;
  margin-right: 8px;
  float: left;
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  /* NEW, Spec - Firefox, Chrome, Opera */
  align-items: flex-end;
  -webkit-align-items: flex-end;
  /* Safari 7.0+ */
}

.progress-bar-vertical .progress-bar {
  width: 100%;
  height: 0;
  background-color: #4cdc8e;
  -webkit-transition: height 0.6s ease;
  -o-transition: height 0.6s ease;
  transition: height 0.6s ease;
}
