.textLimitNote {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}
.text1Line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.textDetail {
  font-weight: 500;
  font-size: 14px;
  color: #323c47;
}

.breakWord {
  overflow-wrap: break-word;
}

.text-custom-red {
  color: #f7685b !important;
}

.empty-placeholder{
  font-size: 14px;
  margin: 15px 6px 10px;
  color: rgb(112, 118, 131)
}

@mixin text-color($color) {
  color: $color;
}
