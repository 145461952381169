.img-list-group {
  width: 50px;
  height: 50px;
  min-height: 50px;
  border-radius: 50%;
}

.new-group-chat {
  label {
    font-size: 0.8rem;
    .fa {
      font-size: 2.25rem;
    }
    span {
      margin-top: 0.5rem;
    }
  }
  img {
    width: intrinsic;
    width: -moz-max-content;
    width: -webkit-max-content;
    max-width: 20px;
    max-height: 20px;
  }
}

.image-group-selector {
  height: 200px;
  width: 200px;
  cursor: pointer;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  color: white;
  background-color: #747474;
  display: block;
  text-align: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
}

.checkbox-custom {
  height: 20px;
}

.my-member-container {
  padding: 10px 20px;
  height: calc(100vh - 4em - 100px);
  overflow-y: auto;
  background-color: white;
}

.image-member {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}

.my-control-input {
  width: 20px;
}

.content-fit {
  width: intrinsic;
  width: -webkit-max-content;
  width: -moz-max-content;
}