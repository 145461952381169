.dummy-relative {
  position: relative;
}

.custom-select-with-add {
  border: solid 1px $border-color;
  width: 100%;
  height: 38px;
  padding: 2px 8px;
  border-radius: 5px;
  background-color: white;
  line-height: 22px;

  &:hover,
  &.active {
    border: solid 0.5px $sidely-blue !important;
  }

  p {
    color: hsl(0, 0%, 50%);
    margin-left: 2px;
    margin-right: 2px;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    box-sizing: border-box;
  }
}

.custom-chevron-with-add {
  position: absolute;
  right: 15px;
  top: 0px;

  width: 30px;
  height: 30px;
  color: #cccccc;
  padding-top: 10px;
  border: none;
  font-size: 14px;

  ::after {
    content: '';
    width: 1px;
    height: 20px;
    position: absolute;
    background: $border-color;
    top: 9px;
    left: 0px;
  }
}

.custom-dropdown-box {
  position: absolute;
  top: 30px;
  right: -205px;
  z-index: 1;

  width: 250px;
  height: 200px;
  background: #ffffff;
  border: solid 0.5px #ebeff2;
  border-radius: 5px;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.13);

  .custom-options {
    width: 250px;
    height: 170px;
    margin-left: -13px;

    ul {
      padding-left: 1px !important;
      padding-right: 1px !important;
      margin-top: 5px !important;
      margin-bottom: 5px !important;

      li {
        list-style-type: none;
        font-size: 13px;
        line-height: 32px;
        color: #4c5862;
        padding-left: 10px;

        &:hover {
          background-color: $sidely-blue-blur;
        }
      }
    }
  }

  .btn-create-dropdown {
    position: absolute;
    bottom: 0px;
    left: 0px;
    z-index: 2;

    width: 250px;
    height: 30px;
    background: transparent;
    border: none;

    ::before {
      position: absolute;
      top: 0px;
      left: 25px;

      content: '';
      width: 200px;
      height: 1px;
      background: $border-color;
    }

    p {
      color: $sidely-blue;
      font-weight: 500;
      font-size: 13px;
      line-height: 20px;
      margin: 0px;
    }
  }
}

.no-options {
  position: absolute;
  top: 80px;
  left: 85px;
  font-size: 13px !important;
}

.autocomplete-dropdown-container {
  background-color: white;
  position: absolute;
  left: 0px;
  top: 2px;
  z-index: 1;
  width: 100%;
  border: 1px solid $border-color;
  border-radius: 5px;
  font-size: 12px;

  span {
    margin: 10px;
    line-height: 30px;
  }
}

.suggestion-item--active {
  background-color: $sidely-blue-blur !important;
}
