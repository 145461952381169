/* Buttons */
.btn {
  font-size: $btn-font-size;
  line-height: 1;
  // font-family: $type-1;

  &:disabled {
    background: #c1c1c1 !important;
    border-color: #c2cfe0 !important;
    box-shadow: none !important;
    color: #ffffff;
    cursor: not-allowed;
    &:hover {
      background: #c1c1c1 !important;
      border-color: #c2cfe0 !important;
    }
  }

  i {
    margin-right: 0.3125rem;
  }

  // .filter-table {
  //   background-color: transparent;
  //   border: 1px solid transparent;
  //   padding: 0;
  //   cursor: pointer;
  // }

  .btn-label {
    &:before {
      font-size: 1rem;
      line-height: 5px;
      vertical-align: middle;
    }

    &.btn-label-left {
      margin-right: 5px;
    }

    &.btn-label-right {
      margin-left: 5px;
    }
  }

  &.btn-plus {
    height: 60px;
    width: 60px;
    border-radius: 25px;
    padding: 0 !important;

    i {
      margin: 0;
      font-size: 18px;
    }
  }

  &.btn-light-cs {
    background: #f9f9f9;
    color: #828282;
    border-radius: 3px;
    // width: 120px;
    // height: 30px;
    font-size: 10px;
    line-height: 10px;
    border: 1px solid #e0e0e0;

    &:hover {
      background: #fff;
      color: #333333;
    }
  }

  &.btn-white-cs {
    background: #f9f9f9;
    color: #ffffff;
    border-radius: 3px;
    font-size: 10px;
    line-height: 10px;
    border: 1px solid #e0e0e0;
  }

  &.btn-gray {
    background: #bdbdbd;
    color: #fff;

    &:hover {
      background: #fff;
      color: #333333;
    }
  }

  &.btn-rounded {
    @include border-radius(50px);
  }

  &.btn-icons {
    width: 40px;
    height: 40px;
    padding: 10px;
    text-align: center;
    vertical-align: middle;

    i {
      margin: auto;
      line-height: initial;
    }
  }

  &.btn-fw {
    min-width: $button-fixed-width;
  }

  &.icon-btn {
    i {
      margin-right: 0;
    }
  }

  &.social-btn {
    padding: $social-btn-padding;

    i {
      margin-right: 0;
      font-size: $social-btn-icon-size;
    }
  }

  &.btn-sm {
    font-size: $btn-font-size-sm;
  }

  &.btn-lg {
    font-size: $btn-font-size-lg;
  }

  &.btn-xs {
    padding: $btn-padding-y-xs $btn-padding-x-xs;
    font-size: $btn-font-size-xs;
  }

  &.btn-danger,
  &.btn-info,
  &.btn-success,
  &.btn-teal,
  &.btn-warning {
    color: $white;
  }

  &.btn-outline-light {
    border-color: darken(theme-color(light), 15%);
    color: darken(theme-color(light), 15%);
  }

  &.btn-outline-secondary {
    color: rgba($black, 0.5);
  }

  &.btn-inverse-secondary {
    background-color: rgba(theme-color(secondary), 0.5);
    color: rgba($black, 0.5);

    &:hover {
      color: rgba($black, 0.5);
    }
  }

  &.btn-inverse-light {
    background-color: $white;
    color: rgba($black, 0.5);
    border-color: lighten($black, 85%);

    &:hover {
      color: rgba($black, 0.5);
      border-color: lighten($black, 85%);
    }
  }

  &.btn-link {
    color: #000000;
    font-size: 10px;

    &.see-more {
      font-size: 12px !important;
      text-align: right;
    }

    &.see-less {
      font-size: 12px !important;
      text-align: left;
    }
  }

  &.btn-primary-outline {
    border: 1px solid #00b2ff;
    color: #00b2ff;
  }

  &.btn-save {
    width: 150px;
    height: 32px;
    margin-top: -2px;
    &:hover{
      color: #FFF;
    }
  }

  &.btn-login {
    width: 100%;
    height: 32px;
    margin-top: -2px;
  }

  &.btn-invisible {
    padding: 0px;
  }
}

.btn-cancel-status {
  background-color: #fff;
  border-color: #f7685b;
  color: #f7685b;
  &:hover {
    background-color: #fff;
    color: #f7685b;
    border-color: #f7685b;
  }
}

.btn-group {
  border: $border-width solid $border-color;
  @include border-radius($btn-border-radius);

  .btn {
    border: none Important;

    &:last-child {
      border-right: none;
    }

    &.btn-primary {
      border-color: darken(theme-color(primary), 3%);
    }

    &.btn-secondary {
      border-color: darken(theme-color(secondary), 3%);
    }

    &.btn-info {
      border-color: darken(theme-color(info), 3%);
    }

    &.btn-warning {
      border-color: darken(theme-color(warning), 3%);
    }

    &.btn-success {
      border-color: darken(theme-color(success), 3%);
    }

    &.btn-danger {
      border-color: darken(theme-color(danger), 3%);
    }

    &.btn-dark {
      border-color: darken(theme-color(dark), 3%);
    }

    &.btn-light {
      border-color: darken(theme-color(light), 3%);
    }
  }
}

.btn-group-cs {
  border: solid 1px #4e8ccb;

  button {
    font-size: 12px !important;
    color: #4e8ccb;
    border: solid 1px #4e8ccb;
    padding: 6px !important;
    width: 35px;

    &.active {
      background: #4e8ccb;
      color: #fff;
    }
  }
}

.btn-toolbar {
  .btn-group {
    + .btn-group {
      @extend .ml-2;
    }
  }
}

.btn-primary {
  background: #00b2ff !important;
  border-color: #00b2ff !important;
  box-shadow: 0px 4px 10px rgba(16, 156, 241, 0.24);

  &:hover {
    background-color: #4e8ccb !important;
    border-color: #4e8ccb !important;
  }
}

.btn-outline-primary {
  color: #4e8ccb;
  border-color: #4e8ccb;

  &:hover {
    background-color: #4e8ccb;
    border-color: #4e8ccb;
  }
}

.btn-xs {
  .lds-dual-ring {
    width: 15px;
    height: 13px;

    &:after {
      width: 15px;
      height: 15px;
    }
  }
}

/*social buttons*/
@each $color, $value in $social-colors {
  .btn-#{$color} {
    @include social-button(social-color($color));
  }
}

/*social buttons*/
@each $color, $value in $social-colors {
  .btn-social-outline-#{$color} {
    @include btn-social-outline-variant(social-color($color));
  }
}

/* inverse buttons */
@each $color, $value in $theme-colors {
  .btn-inverse-#{$color} {
    @include button-inverse-variant($value);
  }
}

/* Inverse Outlined Buttons */
@each $color, $value in $theme-colors {
  .btn-inverse-outline-#{$color} {
    @include button-inverse-outline-variant($value);
  }
}

.btn-sm-cs {
  font-size: 12px;
  padding: 9px 15px;
  background: #4e8ccb;
}

.btn-green {
  background: #3c8105 !important;
  color: #fff !important;
}

.btn-red {
  background: $red !important;
  color: #fff !important;
}

.btn-grey {
  background: #828282 !important;
  color: #fff !important;
}

.btn-login {
  border: 1px solid #e0e0e0;
  border-radius: 5px;

  img {
    padding-left: 20px;
  }

  .btn-label {
    font-weight: 300;
    color: #828282;
    font-size: $default-font-size;
    padding: 22px 68px;
    cursor: pointer;
  }
}

.border-transparent {
  border: none;

  .btn {
    border: none;

    &:hover {
      // background-color: $sidely-blue-blur !important;
      background-color: transparent !important;
      border-radius: 0;
    }

    &:focus {
      outline: none !important;
      border-color: transparent;
      background-color: transparent !important;
      border-radius: 0;
    }
  }
}

.border-Icon {
  border: none;

  .btn {
    border: none;

    &:hover {
      border-radius: 0;
    }

    &:focus {
      outline: none !important;
      border-color: transparent;
      border-radius: 0;
    }
  }
}

.btn-login-google {
  border-radius: 10px !important;
  border: 1px solid #e0e0e0 !important;
  box-shadow: unset !important;
  width: 300px;
  height: 56px;

  img {
    margin-left: 10px;
  }

  label {
    font-family: 'Helvetica Neue', sans-serif;
    font-weight: 300;
    color: #828282;
    font-size: 12px;
    padding-left: 68px;
  }
}

.btn-save {
  border: 1px solid$sidely-blue;
  background: $sidely-blue;
  border-radius: 4px;
  padding: 5px 45px;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 19px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.01em;

  /* white */

  color: #ffffff;
  cursor: pointer;

  &:hover {
    background: #005377;
  }
}

.btn-save-formbuilder {
  border: 2px solid $sidely-blue;
  background: white;
  border-radius: 4px;
  padding: 5px 45px;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 19px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.01em;

  /* white */

  color: #ffffff;
  cursor: pointer;

  &:hover,
  &:disabled {
    background: $sidely-blue !important;
    border: 2px solid $sidely-blue !important;

    .text-save-formbuilder {
      color: white;
    }
  }
}

.btn-preview-formbuilder {
  border: 2px solid $sidely_blue;
  background: $sidely-blue;
  border-radius: 4px;
  padding: 5px 45px;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 19px;
  /* identical to box height */
  padding-right: 35px;
  padding-left: 35px;

  text-align: center;
  letter-spacing: 0.01em;

  /* white */

  color: #ffffff !important;
  cursor: pointer;

  &:hover {
    background: #005377;
  }
}

.btn-no-caret {
  font-size: 0 !important;
}

.filter-table {
  border: none;
  background-color: transparent;
}

.relative-btn-container {
  position: relative;
}

.relative-btn-container:hover .btn-delete-absolute {
  display: block;
}

.relative-btn-container:hover .btn-edit-absolute {
  display: block;
}

.btn-edit-absolute {
  position: absolute;
  right: 32px;
  top: 0px;
  z-index: 1;
  display: none;
  border: none;
  background-color: transparent;
}

.btn-delete-absolute {
  position: absolute;
  right: 5px;
  top: 0px;
  z-index: 1;
  display: none;
  border: none;
  background-color: transparent;
}

.loading-absolute {
  position: absolute;
  top: 40%;
  left: 44%;
  background-color: white;
  width: auto !important;
  height: auto !important;
  border-radius: 5px;
}

.btn-AssignList {
  border-radius: 50px;
}

.btn-convert {
  width: 200px;
  height: 25px;
  border-radius: 5px;
  margin-right: 10px;
  background-color: white;
  font-size: 12px;
  line-height: 21px;

  &.active {
    border: 1px solid $sidely-blue;
    color: $sidely-blue;
  }

  &.inactive {
    border: 1px solid $border-color;
    color: $border-color;
  }
}

.react-datepicker__close-icon {
  &:after {
    background-color: transparent !important;
    color: hsl(0, 0%, 80%) !important;
    margin: 0px 5px 4px 0 !important;
    font-size: 23px !important;
  }
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  background-color: white;
  border-color: none;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: none;
}
