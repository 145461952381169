/* Navbar */

.navbar {
  &.default-layout {
    font-family: $type-2;
    background: $daxium-dark-color;
    transition: background $action-transition-duration
      $action-transition-timing-function;
    -webkit-transition: background $action-transition-duration
      $action-transition-timing-function;
    -moz-transition: background $action-transition-duration
      $action-transition-timing-function;
    -ms-transition: background $action-transition-duration
      $action-transition-timing-function;
    .navbar-brand-wrapper {
      transition: width $action-transition-duration
          $action-transition-timing-function,
        background $action-transition-duration
          $action-transition-timing-function;
      -webkit-transition: width $action-transition-duration
          $action-transition-timing-function,
        background $action-transition-duration
          $action-transition-timing-function;
      -moz-transition: width $action-transition-duration
          $action-transition-timing-function,
        background $action-transition-duration
          $action-transition-timing-function;
      -ms-transition: width $action-transition-duration
          $action-transition-timing-function,
        background $action-transition-duration
          $action-transition-timing-function;
      background: $daxium-dark-color;
      border-right: 1px solid #fff;
      .sidebar-dark & {
        background: $sidebar-dark-bg;
      }
      width: $sidebar-width-lg;
      height: $navbar-height;
      .navbar-brand {
        color: $white;
        font-size: 1.5rem;
        line-height: 48px;
        margin-right: 0;
        height: 50px;
        padding: 0.25rem 0;
        @include display-flex;
        &:active,
        &:focus,
        &:hover {
          color: lighten(color(gray-dark), 10%);
        }
        img {
          max-height: 39px;
          max-width: 100%;
          width: auto;
          margin: 2px auto 0;
          vertical-align: middle;
        }
      }
      .mini-toggle-bar {
        display: none;
      }
      .brand-logo-mini {
        display: none;
        img {
          // width: calc(#{$sidebar-width-icon} - 50px);
          width: auto;
          max-width: 100%;
          max-height: 25px;
          margin: auto;
        }
      }
    }
    .navbar-menu-wrapper {
      transition: width $action-transition-duration
        $action-transition-timing-function;
      -webkit-transition: width $action-transition-duration
        $action-transition-timing-function;
      -moz-transition: width $action-transition-duration
        $action-transition-timing-function;
      -ms-transition: width $action-transition-duration
        $action-transition-timing-function;
      color: $white;
      // padding-left: 15px;
      // padding-right: 15px;
      width: calc(100% - #{$sidebar-width-lg});
      height: $navbar-height;
      @media (max-width: 991px) {
        width: auto;
      }
      .navbar-toggler {
        border: 0;
        color: inherit;
        &:not(.navbar-toggler-right) {
          @media (max-width: 991px) {
            display: none;
          }
        }
      }
      .navbar-text {
        font-size: $default-font-size;
      }
      .navbar-nav {
        flex-direction: row;
        align-items: center;
        .nav-item {
          // margin-left: 1rem;
          // margin-right: 1rem;
          .nav-link {
            color: inherit;
            font-size: $navbar-font-size;
            vertical-align: middle;
            @media (max-width: 767px) {
              margin-left: 0.5rem;
              margin-right: 0.5rem;
            }
            i {
              font-size: $navbar-icon-font-size;
              vertical-align: middle;
            }
            .profile-text {
              margin-right: 15px;
              .rtl & {
                margin-right: 0;
                margin-left: 15px;
              }
            }
            &.nav-btn {
              .btn {
                background: rgba($white, 0.1);
                padding: 0.75rem 1rem;
                color: $white;
              }
              &:after {
                display: none;
              }
            }
            &:hover {
              background: $white;
              color: $daxium-dark-color;
            }
          }
          &.color-setting {
            i {
              font-size: 25px;
              vertical-align: text-top;
            }
          }
        }
        &.navbar-nav-right {
          height: 50px;
          &.action-right {
            .nav-item {
              border-left: 1px solid #fff;
              .nav-link {
                height: 50px;
                padding: 0 15px;
                line-height: 50px;
              }
            }
          }
          @media (min-width: 992px) {
            margin-left: auto;
            .rtl & {
              margin-left: 0;
              margin-right: auto;
            }
          }
        }
        &.header-links {
          height: $navbar-height;
          min-width: 200px;
          @media (max-width: 1024px) {
            overflow-x: scroll;
          }
          // padding-left: 2%;
          .nav-item {
            margin: 0;
            .nav-link {
              height: $navbar-height;
              font-size: $navbar-font-size;
              padding: 16px 25px;
              @include display-flex;
              @include align-items(center);
              i {
                margin-right: 10px;
                font-size: 21px;
                .rtl & {
                  margin-right: 0;
                  margin-left: 10px;
                }
              }
            }
            &.active {
              background: rgba($white, 0.13);
            }
          }
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .navbar {
    justify-content: unset;
    &.default-layout {
      flex-direction: row;
      .navbar-menu-wrapper {
        width: calc(100% - 222px);
        justify-content: space-between;
      }
      .navbar-brand-wrapper {
        width: 222px;
        .mini-toggle-bar {
          display: block;
          padding: 15px;
          color: #fff !important;
          font-size: 17px;
        }
        .brand-logo {
          display: none;
        }
        .brand-logo-mini {
          display: inline-block;
          line-height: 38px;
          img {
            max-height: 27px;
          }
        }
      }
    }
  }
  .navbar-collapse {
    display: flex;
    margin-top: 0.5rem;
  }
}

@media (max-width: 480px) {
  .navbar-menu-wrapper {
    width: 100% !important;
  }
}
