@mixin theme(
  $name,
  $header-bg,
  $title-bg,
  $sidebar-bg,
  $hover-bg,
  $btn-bg,
  $textColor
) {
  .theme-#{$name} {
    .navbar {
      &.default-layout {
        .navbar-brand-wrapper {
          background: $header-bg !important;
        }
        background: $header-bg !important;
      }
    }
    .sidebar {
      background: $sidebar-bg !important;
      box-shadow: 1px 0px 0px rgba(0, 0, 0, 0.02);
      .menu_header_color {
        background-color: $title-bg !important;
        .icon {
          color: $btn-bg !important;
        }
      }
      .nav {
        .nav-item {
          .collapse.show,
          .collapsing {
            background: $sidely-blue;
          }
          &.active {
            > .nav-link {
              background: $sidely-blue !important;
            }
          }
        }
        &:not(.sub-menu) {
          > .nav-item {
            &:hover {
              &:not(.nav-profile) {
                > .nav-link {
                  background: #ffffff;
                }
              }
            }
          }
        }
        &.sub-menu {
          .nav-item {
            &:hover {
              > .nav-link {
                background: #ffffff !important;
              }
            }
          }
        }
      }
    }
    .navbar-subtop {
      // background-color: $sidebar-bg !important;
      .navbar-nav {
        .nav-link {
          &:hover {
            background-color: $hover-bg;
            color: #000;
          }
        }
      }
    }
    .btn-primary {
      background: $btn-bg;
      border-color: $btn-bg;
      &:hover {
        color: #fff;
        background-color: $title-bg;
        border-color: $title-bg;
      }
    }
    .btn-green {
      background: #00b140 !important;
      border-color: #00b140 !important;
      &:hover {
        color: #fff;
        background-color: $title-bg;
        border-color: $title-bg;
      }
    }
    .ant-btn-primary {
      background: $btn-bg;
      border-color: $btn-bg;
      &:hover {
        color: #fff;
        background-color: $title-bg;
        border-color: $title-bg;
      }
    }
    .btn-outline-primary {
      color: $btn-bg;
      border-color: $btn-bg;
      &:hover {
        color: #fff;
        background-color: $btn-bg;
        border-color: $btn-bg;
      }
    }
    .btn-group-cs {
      border: unset;
      button {
        color: $btn-bg;
        border: solid 1px $btn-bg;
        &:last-child {
          border-right: solid 1px !important;
        } // border: solid 1px $btn-bg;
        &.active {
          background: $btn-bg;
          color: #fff;
        }
      }
    }
    .pagination {
      .page-item {
        color: $btn-bg;
        &.active .page-link {
          background-color: $btn-bg;
          border-color: $btn-bg;
        }
      }
    }
    .ant-switch-checked {
      background-color: $btn-bg;
    }
    .tab-dax {
      .nav-item {
        a {
          &.active {
            border-color: #fff #fff $btn-bg !important;
            color: $btn-bg;
            a {
              border-color: #fff #fff $btn-bg !important;
              color: $btn-bg;
            }
          }
        }
      }
    }
    // check
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: $btn-bg;
      border-color: $btn-bg;
    }
    .dropdown-menu-right {
      a {
        color: $btn-bg;
      }
    }
    .dropdown-menu {
      box-shadow: 0px 1px 5px #cfcfcf;
      
      .nav-link {
        button {
          color: $btn-bg !important;
        }
      }
    }
    .frame-chat {
      .sidepanel {
        .profile {
          background: $header-bg !important;
        }
        .room ul li.room-list:hover {
          background-color: $hover-bg !important;
        }
        .room ul li.room-list:active {
          background-color: $hover-bg !important;
        }
        .bottom-bar-leftpanel .btn {
          background-color: $header-bg !important;
        }
      }
      .content {
        .contact-profile {
          background: $header-bg !important;
        }
        .message-input {
          background: $header-bg !important;
        }
        .messages {
          .grouping-date {
            background-color: $hover-bg !important;
          }
          ul li.replies p {
            background-color: $hover-bg !important;
          }
        }
      }
      .rightpanel {
        .profile {
          background: $header-bg !important;
        }
      }
    }
    .right-panel-active {
      .rightpanel {
        .info-member .info-member-head {
          color: $header-bg !important;
        }
        .form-group .input-group .input-group-append span i {
          color: $header-bg !important;
        }
      }
    }
  }
}
// @include theme(
//   'chocolate',
//   #8e5564,
//   #cf9aa9,
//   #f1ced8,
//   #f7e1e7,
//   #b86077,
//   'white'
// );
// @include theme('blue2', #0e84c4, #1a9de5, #e1ebf5, #cce6f3, #4d8cca, 'white');
// @include theme('blue', #263541, #79bde0, #e1ebf5, #cce6f3, #4d8cca, 'white');
// @include theme('pink', #bf5f82, #f48fb1, #ffdaef, #ffc1e3, #f06292, 'white');
// @include theme('green', #519657, #81c784, #d2fad2, #b2fab4, #4caf50, 'white');
// @include theme('orange', #c79a00, #ffca28, #fbffbf, #ffff81, #fb8c00, 'white');
// @include theme('purple', #74378d, #74378d, #ffffff, #74378d, #7f3f98, 'white');
// @include theme('black', #1b1b1b, #aeaeae, #eeeeee, #e0e0e0, #000000, 'white');
@include theme('sidely', #ffffff, #ffffff, #ffffff, #00b2ff, #000000, 'white');
