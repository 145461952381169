.role-wrapper {
  .role-sidebar {
    .list-group-item {
      padding: 19px 15px;
      font-size: 11px;
      border-radius: 0px;
      border: none;
      &:nth-child(odd) {
        background: #fff;
      }
      &:nth-child(even) {
        background: #fbfbfb;
      }
      &.active {
        background: #7f3f98;
      }
    }
    .list-group {
      .list-group-item {
        padding: 19px 15px;
        font-size: 11px;
        border-radius: 0px;
        border: none;
        &:nth-child(odd) {
          background: #fff;
        }
        &:nth-child(even) {
          background: #fbfbfb;
        }
        &.active {
          background: #7f3f98;
        }
      }
    }
  }
  .role-content {
    padding-right: 0;
    border-top: 1.3px solid #4f4f4f;
    background: #fbfcff;
    .list-group {
      box-shadow: inset 2px 0px 8px #e5eaf9;
      .list-group-item {
        background: transparent;
        border-radius: 0px;
        padding: 14px 20px;
        font-size: 11px;
        font-weight: bold;
        border-left: none;
        border-right: none;
        border-color: #e1e7f8;
        text-transform: capitalize;
        &.title {
          padding: 15px 20px;
          line-height: 20px;
          text-transform: uppercase;
          color: #bdbdbd;
        }
      }
    }
  }
}
