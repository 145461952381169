.detail-top {
  background-color: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.03);
  border-radius: 4px;
  margin-top: 7.5em;
  .nav-status {
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    text-align: center;
    color: #90a0b7;
    cursor: default;
    background-color: #f9f9fb;
    &.status-suspect,
    &.status-identified-prospect {
      background-color: $sidely-blue;
      font-size: 12px;
      color: #ffffff;
    }
    &.status-lead,
    &.status-in-progress {
      background-color: #ff9345;
      font-size: 12px;
      color: #ffffff;
    }
    &.status-prospect,
    &.status-proposal-sent {
      background-color: #ffea2c;
      font-size: 12px;
      color: #ffffff;
    }

    &.status-under-negotiation {
      background-color: #aaed3c;
      font-size: 12px;
      color: #ffffff;
    }

    &.status-client,
    &.status-signed {
      background-color: #2ed474;
      font-size: 12px;
      color: #ffffff;
    }
    &.status-inactive,
    &.status-cancelled {
      background-color: #aeaeae;
      font-size: 12px;
      color: #ffffff;
    }
  }
  p {
    font-size: 12px !important;
  }

  .detail-head {
    span {
      margin-left: 10px;
      font-size: 13px;
      color: #90a0b7;
    }
    p {
      // font-size: 12px !important;
      color: rgba(46, 51, 54, 0.8);
      margin-bottom: 0.5em;
    }
  }

  .detail-body {
    p {
      // font-size: 14px;
      margin-bottom: 0.5em;
      color: rgba(37, 65, 83, 0.8);
    }

    .detail-value {
      font-size: 14px;
      color: #323c47;
    }

    .detail-value-key {
      font-weight: 500;
      font-size: 14px;
      color: #323c47;
    }
  }
}

.tab-custom {
  li {
    margin-left: 0px;
    margin-right: 0px;
    a {
      border: none !important;
      &.active {
        border-bottom: 2px solid $sidely-blue !important;
        h2 {
          color: $sidely-blue;
          // font-weight: bold;
          // font-size: 12px;
        }
      }
    }
  }
}

.detail-head-tab {
  // margin-top: 1px;
  li {
    margin-left: 0px;
    margin-right: 0px;
    a {
      background: #fafafa;
      box-shadow: inset 0px 0px 8px rgba(0, 0, 0, 0.06);
      padding: 0px !important;
      width: 126px;
      &.active {
        background-color: transparent !important;
        box-shadow: inset 0px 0px 0px white;
        border: none !important;
        padding-top: 2px !important;

        h2 {
          color: rgba(37, 65, 83, 0.8) !important;
        }
      }

      h2 {
        color: #c7c7c7;
        font-size: 11px;
        text-align: center;
        line-height: 23px !important;
      }
    }
  }
}
