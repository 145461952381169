/* Cards */
.card {
  border: 0;
  @include border-radius(2px);

  &.opportunities {
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.07);
    border-radius: 3px;
  }

  &.custom-card {
    background: #ffffff;
    // box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
    border-radius: 4px !important;
    width: 250px;
    height: 100px;

    .card-header {
      border-width: 0px;
      border-radius: 4px 4px 0px 0px !important;
    }
  }

  .card-body {
    // padding: $card-padding-y $card-padding-x;
    // box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
    border-radius: 6px;

    + .card-body {
      padding-top: 0;
    }

    &.card-no-shadow {
      background: #ffffff;
      box-shadow: none !important;
    }
  }

  &.card-notes {
    background: #ffffff;
    // box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
    border-radius: 4px !important;
  }

  &.card-docs {
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
    border-radius: 4px !important;
  }

  &.card-event {
    border: 1px solid $border-color;
    border-right: none;

    &.to-do {
      border-left: 9px solid #ffb946;
    }

    &.completed {
      border-left: 9px solid #2ed474;
    }

    &.cancelled {
      border-left: 9px solid #f7685b;
    }
  }

  &.card-activity {
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
  }

  &.card-outline-success {
    border: 1px solid theme-color('success');
  }

  &.card-outline-primary {
    border: 1px solid theme-color('primary');
  }

  &.card-outline-warning {
    border: 1px solid theme-color('warning');
  }

  &.card-outline-danger {
    border: 1px solid theme-color('danger');
  }

  &.card-rounded {
    @include border-radius(5px);
  }

  &.card-faded {
    background: #b5b0b2;
    border-color: #b5b0b2;
  }

  &.card-circle-progress {
    color: $white;
    text-align: center;
  }
}

@each $color, $value in $theme-colors {
  .card-inverse-#{$color} {
    @include card-inverse-variant(
      rgba(theme-color($color), 0.2),
      theme-color-level($color, 1),
      theme-color-level($color, 3)
    );
  }
}

.card-dir {
  .card {
    cursor: pointer;

    &:hover {
      box-shadow: 1px 1px 3px 0px #ccc;
    }
  }

  img {
    width: 70px;
    height: 70px;
    object-fit: cover;
    background: #fff;
  }

  .btn-edit-card {
    position: absolute;
    right: 0px;
    z-index: 999;
  }
}

.rectangle {
  width: 468px;
  height: 40px;
  border-radius: 4px;

  .circle-inside {
    background: white;
    padding-top: 10px;
    margin: 0px !important;
    border-radius: 50px;
  }
}

.circle-probability {
  width: 30px;
  height: 30px;
  border-radius: 50px;

  p {
    padding-top: 6px;
    font-weight: 500 !important;
    text-align: center;
    font-style: normal;
    letter-spacing: 0.01em;
    color: #ffffff !important;
  }
}

.circle-Kanban {
  width: 25px;
  height: 25px;
  border-radius: 50px;

  p {
    padding-top: 3px;
    font-weight: 400 !important;
    text-align: center;
    font-style: normal;
    letter-spacing: 0.01em;
    color: #ffffff !important;
  }
}

.text-opportunities {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #323c47;
}

.text-calendar-black {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #324c47;
}

.text-calendar-gray {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #707683;

  &.time {
    color: #b7b7b7;
  }
}

.text-title-mini {
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;
  color: #ffffff;
}

.text-title {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: #323c47;
}

.text-description {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #4c5862;
}

.text-by {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  letter-spacing: 0.01em;
  color: #90a0b7;
}

// BG OPPORTUNITY
.identified-prospect {
  background: $sidely-blue;
}

.in-progress {
  background-color: #ff9345;
}

.proposal-sent {
  background-color: #ffea2c;
}

.under-negotiation {
  background-color: #aaed3c;
}

.signed {
  background: #2ed47a;
}

.canceled {
  background: #aeaeae;
}

.bg-calendar {
  background: $sidely-blue;
}

.bg-0 {
  background: $opportunity-0;
}

.bg-10 {
  background: $opportunity-10;
}

.bg-20 {
  background: $opportunity-20;
}

.bg-30 {
  background: $opportunity-30;
}

.bg-40 {
  background: $opportunity-40;
}

.bg-50 {
  background: $opportunity-50;
}

.bg-60 {
  background: $opportunity-60;
}

.bg-70 {
  background: $opportunity-70;
}

.bg-80 {
  background: $opportunity-80;
}

.bg-90 {
  background: $opportunity-90;
}

.bg-100 {
  background: $opportunity-100;
}

.card-opp-status {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 130px;
  min-height: 60px;
  border-radius: 10px;
  color: white;
  font-size: 12px;
  margin-right: 12px;
  button {
    background: transparent;
    border: none;
    color: white;
  }
}

.card-opp-status-new {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 130px;
  height: 60px;
  border-radius: 7px;
  color: black;
  font-size: 12px;
  border: 1px dashed #e8e8e8;
  button {
    background: transparent;
    border: none;
    color: black;
  }
}

.card-opp-fields {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 650px;
  height: 55px;
  border-radius: 7px;
  color: black;
  font-size: 14px;
  margin-right: 12px;
  border: 1px solid #e8e8e8;
  button {
    background: transparent;
    border: none;
  }
}

.card-opp-tag {
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  padding: 0 3px;
  height: 30px;
  border-radius: 7px;
  font-size: 12px;
  margin-right: 12px;
  background: white;
}

.color-option {
  border-radius: 4px;
  width: 54px;
  height: 30px;
}

.card-modal-product {
  height: 25rem;
  overflow-y: auto;
  overflow-x: hidden;
  font-size: 12px;
  font-family: 'Poppins', sans-serif;
}

.card-feature {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}


.card-event {
  .tooltip-event {
    visibility: hidden;
    background-color: #fff;
    color: #4f4f4f;
    font-size: 12px;
    text-align: center;
    border-radius: 6px;
    padding: 8px;
    position: absolute;
    z-index: 1;
    top: 2.1rem;
    left: 10px;
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
    border: 0.5px solid #EBEFF2;
  }  
}
.card-event:hover .tooltip-event {
  visibility: visible;
  width: max-content;
}
.line-card{
  border-right: 2.5px solid #E7E7E7;
  // margin: 0 45px;
}
