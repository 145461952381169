::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 6px;
    height: 6px;
}

::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #00b2ff;
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}