.rs-option {
  background-color: #fff;
  color: inherit;
  cursor: default;
  display: block;
  font-size: 12px;
  width: 100%;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
  padding: 10px;
  &:hover {
    background-color: rgb(222, 235, 255);
  }
}

.form-input-dropdown {
  background-color: #e5e5e5;
  border-color: #e5e5e5;
  border-radius: 5px;
  padding-right: 0px;
  padding-left: 0px;
}

.dropdown-toggle::after {
  margin-left: 10px !important;
}

.selectFilter {
  .css-1hwfws3 {
    max-height: 35px !important;
    overflow-y: auto !important;
  }
}

.radius-select-label {
  border-radius: 5px 0px 0px 5px !important;
}

.menulist-cs-icon {
  display: flex;
  flex-wrap: wrap;
  .option-cs-icon {
    flex-basis: 16.5%;
    text-align: center;
  }
}
// .form-input-dropdown:hover {
//   color: #212529;
//   background-color: #d2d2d2;
//   border-color: #cccccc;
// }
