/* Checkboxes and Radios */
.form-check,
.form-radio {
  position: relative;
  display: block;
  margin-top: 15px;
  margin-bottom: 10px;

  .form-check-label {
    display: block;
    padding-left: 30px;

    .rtl & {
      padding-left: 0;
      padding-right: 30px;
    }
    line-height: 1.5;

    input {
      position: absolute;
      margin-left: -20px;
      margin-top: 4px\9;
      top: 0;
      left: 0;

      .rtl & {
        left: auto;
        right: 0;
      }
      z-index: 1;
      cursor: pointer;
      opacity: 0;
      filter: alpha(opacity=0);
      margin-top: 0;
    }
  }
}

.form-check {
  .form-check-label {
    font-size: $default-font-size;
    line-height: 1.5;
    padding-left: 10px;

    input {
      &:checked {
        + .input-helper {
          &:before {
            background-color: #00b2ff;
            border-radius: 5px;
            border: none;
          }

          &:after {
            width: 18px;
            opacity: 1;
            line-height: 18px;
            filter: alpha(opacity=100);
            -webkit-transform: scale(1);
            -ms-transform: scale(1);
            -o-transform: scale(1);
            transform: scale(1);
          }
        }
      }

      &:disabled {
        + .input-helper {
          &:before {
            border-color: $border-color;
          }
        }

        &:checked {
          + .input-helper {
            &:after {
              color: $border-color;
            }
          }
        }
      }
    }

    .input-helper {
      &:before {
        position: absolute;
        content: '';
        top: 50%;
        @include transform(translateY(-50%));
        width: 18px;
        height: 18px;
        border-radius: 2px;
        left: 0;

        .rtl & {
          left: auto;
          right: 0;
        }
        border: 2px solid $border-color;
        -webkit-transition: all;
        -o-transition: all;
        transition: all;
        transition-duration: 0s;
        -webkit-transition-duration: 250ms;
        transition-duration: 250ms;
      }

      &:after {
        -webkit-transition: all;
        -o-transition: all;
        transition: all;
        transition-duration: 0s;
        -webkit-transition-duration: 250ms;
        transition-duration: 250ms;
        font-family: Material Design Icons;
        opacity: 0;
        filter: alpha(opacity=0);
        -webkit-transform: scale(0);
        -ms-transform: scale(0);
        -o-transform: scale(0);
        transform: scale(0);
        content: '\F12C';
        position: absolute;
        font-size: 0.9375rem;
        font-weight: bold;
        left: 0;

        .rtl & {
          left: auto;
          right: 0;
        }
        top: 14%;
        @include transform(translateY(-14%));
        color: white;
      }
    }
  }

  &.form-check-flat {
    label {
      input {
        &:checked {
          + .input-helper {
            &:before {
              background-color: theme-color(success);
              border: none;
            }
          }
        }

        &:disabled {
          + .input-helper {
            &:after {
              color: color(white);
            }

            &:before {
              border-color: $border-color;
            }
          }

          &:checked {
            + .input-helper {
              &:before {
                background: color(gray-lightest);
              }
            }
          }
        }
      }

      .input-helper {
        &:before {
          border: 2px solid $border-color;
        }

        &:after {
          color: color(white);
        }
      }
    }
  }
}

.form-radio {
  label {
    input {
      + .input-helper {
        &:before {
          position: absolute;
          content: '';
          top: 50%;
          @include transform(translateY(-50%));
          left: 0;

          .rtl & {
            left: auto;
            right: 0;
          }
          border: 2px solid $border-color;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          -webkit-transition: all;
          -o-transition: all;
          transition: all;
          transition-duration: 0s;
          -webkit-transition-duration: 250ms;
          transition-duration: 250ms;
        }

        &:after {
          content: '';
          width: 8px;
          height: 8px;
          background: theme-color(danger);
          border-radius: 50%;
          top: 30%;
          @include transform(translateY(-30%));
          left: 6px;

          .rtl & {
            left: auto;
            right: 6px;
          }
          -webkit-transition: all;
          -o-transition: all;
          transition: all;
          transition-duration: 0s;
          -webkit-transition-duration: 250ms;
          transition-duration: 250ms;
          opacity: 0;
          filter: alpha(opacity=0);
          -webkit-transform: scale(0);
          -ms-transform: scale(0);
          -o-transform: scale(0);
          transform: scale(0);
          position: absolute;
        }
      }

      &:checked {
        + .input-helper {
          &:before {
            background-color: $white;
            border: 2px solid $border-color;
            top: 30%;
            @include transform(translateY(-30%));
          }

          &:after {
            opacity: 1;
            line-height: 1.5;
            filter: alpha(opacity=100);
            -webkit-transform: scale(1);
            -ms-transform: scale(1);
            -o-transform: scale(1);
            transform: scale(1);
          }
        }
      }

      &:disabled {
        + .input-helper {
          &:before {
            border-color: $border-color;
          }
        }

        &:checked {
          + .input-helper {
            &:before {
              background-color: $white;
              border-color: $border-color;
            }

            &:after {
              background-color: $border-color;
            }
          }
        }
      }
    }
  }

  &.form-radio-flat {
    label {
      input {
        &:checked {
          + .input-helper {
            &:before {
              background: theme-color(success);
              border-color: theme-color(success);
              top: 50%;
              @include transform(translateY(-50%));
            }

            &:after {
              width: 20px;
              height: 20px;
              top: 50%;
              @include transform(translateY(-50%));
              left: -2px;

              .rtl & {
                left: auto;
                right: -2px;
              }
              color: color(white);
              background: none;
              content: '\F12C';
              font-family: Material Design Icons;
              text-align: center;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}

.round {
  position: relative;
  label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 25px;
    left: 5px;
    position: absolute;
    top: 5px;
    width: 25px;
    &:after {
      border: 2px solid #fff;
      border-top: none;
      border-right: none;
      content: '';
      height: 6px;
      left: 7px;
      opacity: 0;
      position: absolute;
      top: 8px;
      transform: rotate(-45deg);
      width: 10px;
    }
  }
  input[type='checkbox'] {
    visibility: hidden;
    width: 25px;
    height: 25px;
  }

  input[type='checkbox']:checked + label {
    background-color: #544397;
    border-color: #544397;
  }

  input[type='checkbox']:checked + label:after {
    opacity: 1;
  }
}

.list-group {
  .list-group-item {
    .custom-checkbox {
      .custom-control-label {
        position: relative;
        cursor: pointer;

        &::before {
          top: 0.05rem;
          background-color: #fff;
          border-color: #00b2ff;
        }
      }
      .custom-control-input-news:checked ~ .custom-control-label {
        &::after {
          content: '';
          position: absolute;
          left: -1.2rem;
          top: 0.2rem;
          border: solid #00b2ff;
          border-width: 0 2px 2px 0;
          width: 6px;
          height: 9px;
          transform: rotate(45deg);
          background-image: none;
        }
      }
    }
  }
}

.checked-event {
  height: 1.5rem;
  width: 1.5rem;
  border: 1px solid #2ed47a;
  border-radius: 50px;
}

.check-sidely {
  input[type='checkbox'] {
    display: none !important;
    &:checked + .checkbox-style,
    &:not(:checked) + .checkbox-style {
      position: relative;
      cursor: pointer;
      &:before {
        content: '';
        position: absolute;
        top: -1px;
        width: 14px;
        height: 14px;
        border-radius: 4px;
        border: 1px solid #d5d5d5;
        background: #fff;
      }
    }
    &:not(:checked) + .borderStyle {
      &:before {
        border: 2px solid #d5d5d5 !important;
      }
    }
    &:checked + .checkbox-style {
      &:before {
        background-color: #00b2ff !important;
        border-color: #00b2ff;
      }
    }
    &:checked + .checkbox-style {
      &:after {
        content: '';
        position: absolute;
        left: 4px;
        width: 6px;
        height: 9px;
        border: solid #fff;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }
}

.form-check-alternative {
  .form-check-label {
    font-size: $default-font-size;
    line-height: 1.5;
    padding-left: 10px;

    input {
      &:checked {
        + .input-helper {
          &:before {
            background-color: #00b2ff;
            border-radius: 5px;
            border: none;
          }

          &:after {
            width: 18px;
            opacity: 1;
            line-height: 1px;
            filter: alpha(opacity=100);
            -webkit-transform: scale(1);
            -ms-transform: scale(1);
            -o-transform: scale(1);
            transform: scale(1);
          }
        }
      }

      &:disabled {
        + .input-helper {
          &:before {
            border-color: $border-color;
          }
        }

        &:checked {
          + .input-helper {
            &:after {
              color: $border-color;
            }
          }
        }
      }

      display: contents;
    }

    .input-helper {
      &:before {
        position: absolute;
        content: '';
        top: 50%;
        @include transform(translateY(-50%));
        width: 18px;
        height: 18px;
        border-radius: 2px;
        left: 20%;

        .rtl & {
          left: auto;
          right: 0;
        }
        border: 2px solid $border-color;
        -webkit-transition: all;
        -o-transition: all;
        transition: all;
        transition-duration: 0s;
        -webkit-transition-duration: 250ms;
        transition-duration: 250ms;
      }

      &:after {
        -webkit-transition: all;
        -o-transition: all;
        transition: all;
        transition-duration: 0s;
        -webkit-transition-duration: 250ms;
        transition-duration: 250ms;
        font-family: Material Design Icons;
        opacity: 0;
        filter: alpha(opacity=0);
        -webkit-transform: scale(0);
        -ms-transform: scale(0);
        -o-transform: scale(0);
        transform: scale(0);
        content: '\F12C';
        position: absolute;
        font-size: 0.9375rem;
        font-weight: bold;
        left: 18%;

        .rtl & {
          left: auto;
          right: 0;
        }
        top: 14%;
        @include transform(translateY(-14%));
        color: white;
      }
    }
  }

  &.form-check-flat {
    label {
      input {
        &:checked {
          + .input-helper {
            &:before {
              background-color: theme-color(success);
              border: none;
            }
          }
        }

        &:disabled {
          + .input-helper {
            &:after {
              color: color(white);
            }

            &:before {
              border-color: $border-color;
            }
          }

          &:checked {
            + .input-helper {
              &:before {
                background: color(gray-lightest);
              }
            }
          }
        }
      }

      .input-helper {
        &:before {
          border: 2px solid $border-color;
        }

        &:after {
          color: color(white);
        }
      }
    }
  }
}
