.login-page {
  height: 100vh;
  .container-login {
    padding: 30px;
    max-width: 360px;
    // height: 250px;
    // width: 350px;
    border: 1px solid #e0e0e0;
    border-radius: 20px;
    .container-logo {
      padding: 25px;
      border: 1px dashed #e0e0e0;
      border-radius: 5px;
    }
  }
}

.error-login {
  button.close {
    padding: 7px 10px;
  }
}

.boxOrLogin {
  padding: 5px;
}

.btn-login-form {
  border-radius: 10px !important;
  // border: 1px solid #e0e0e0 !important;
  box-shadow: unset !important;
  width: 300px;
  height: 165px;
  padding: 15px;
  .btn-color {
    background: $sidely-blue;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
  }
  .login-here {
    // font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: center;

    color: $sidely-blue;
  }
}

.page-login {
  background: #fbf6f6;
  height: 100vh;
  .form {
    background: #254153;
    .form-login {
      height: 80vh;
      margin-bottom: 100px;
    }
    .form-register {
      height: 60vh;
      margin-bottom: 245px;
    }
    label {
      // font-family: Lato;
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 17px;
      color: #00b2ff;
    }
    .logo-sidely {
      height: 70px;
    }
  }
  .image-login {
    background: white;
    .image {
      height: 100vh;
      @media (max-width: 760px) {
        height: 55vh;
      }
      @media (max-width: 1024px) {
        height: 110vh;
      }
      .image-text {
        margin-bottom: 3rem;
        @media (max-width: 760px) {
          height: 40px;
        }
      }
      .image-login-bot {
        @media (max-width: 760px) {
          height: 200px;
        }
      }
      .image-login-top {
        position: absolute;
        left: 50%;
        margin-left: -102.5px;
        z-index: 9999;
        @media (max-width: 760px) {
          height: 182px;
          margin: 40px 0px 0px 82px;
        }
      }
    }
  }
}
