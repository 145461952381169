.main-kanban{
    display: block;
    max-width: 100%;
    margin: auto;
    border-radius: 4px;
    margin-top: 3.5em;
    .bg-kanban{
        background-color: white;
    }
    .header-status{
        min-width: 16.667% !important;
    }
    .header-status-min{
        width: 16.667% !important;
    }
}
