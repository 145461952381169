/* Sidebar */

.sidebar-toggle {
    .sidebar {
        width: 60px;
        .page-header {
            small {
                display: none;
            }
        }
        .img-title {
            display: none;
        }
        .nav {
            overflow: unset;
            .nav-item {
                .nav-link {
                    .menu-title {
                        display: none;
                    }
                }
                .sub-menu {
                    padding: 0 0 0 10px;
                    .nav-item {
                        .nav-link {
                            &.active {
                                &:before {
                                    left: -10px;
                                }
                            }
                        }
                    }
                }
                &:hover {
                    // width: 222px;
                    z-index: 10000;
                    // .nav-link {
                    //   .menu-title {
                    //     display: block;
                    //   }
                    // }
                }
            }
        }
    }
    .main-panel {
        width: calc(100% - 57px);
        transition: width $action-transition-duration;
    }
    .right-panel {
        width: calc(100% - 55px) !important;
        transition: width $action-transition-duration;
    }
}

.right-panel {
    width: calc(100% - 220px) !important;
    transition: width $action-transition-duration;
}

.sidebar {
    overflow: auto;
    min-height: calc(100vh - #{$navbar-height});
    background: $sidebar-light-bg;
    font-family: $type-2;
    padding: 0;
    width: $sidebar-width-lg;
    z-index: 11;
    transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
    -webkit-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
    -moz-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
    -ms-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
    .menu_header_color {
        background-color: #79bde0 !important;
        color: #fff;
        padding: 16px 25px;
    }
    .nav {
        overflow: hidden;
        flex-wrap: nowrap;
        flex-direction: column;
        .nav-item {
            margin: 5px 0;
            padding: 0px;
            .collapse {
                z-index: 999;
            }
            .collapse.show,
            .collapsing {
                background: $sidebar-light-menu-active-bg;
            }
            .nav-link {
                align-items: center;
                display: flex;
                padding: $sidebar-menu-padding;
                white-space: nowrap;
                height: $nav-link-height;
                // background: #00b2ff !important;
                border-radius: 5px !important;
                color: white;
                i {
                    &.menu-arrow {
                        margin-left: auto;
                        margin-right: 0;
                        @include transition-duration(0.2s);
                        @include transition-property(transform);
                        @include transition-timing-function(ease-in);
                        &:before {
                            font-size: 18px;
                            line-height: 1;
                            font-style: normal;
                            vertical-align: middle;
                            color: rgba($sidebar-light-menu-color, 0.5);
                        }
                    }
                }
                &[aria-expanded="true"] {
                    background: $sidebar-light-menu-active-bg;
                    i {
                        &.menu-arrow {
                            @include transform(rotate(90deg));
                        }
                    }
                }
                .menu-icon {
                    margin-right: 0.25rem;
                    width: $sidebar-icon-size;
                    line-height: 1;
                    .rtl & {
                        margin-right: 0;
                        margin-left: 1.25rem;
                    }
                }
                .menu-icon-active {
                    margin-right: 0.25rem;
                    width: $sidebar-icon-size;
                    line-height: 1;
                    display: none;
                }
                .menu-icon-hover {
                    margin-right: 0.25rem;
                    width: $sidebar-icon-size;
                    line-height: 1;
                    display: none;
                }
                .menu-title {
                    color: black;
                    display: inline-block;
                    font-size: $sidebar-menu-font-size;
                    line-height: 1;
                    vertical-align: middle;
                    margin-left: 12px;
                }
                .badge {
                    margin-left: auto;
                }
                &:hover {
                    .menu-title {
                        // color: darken($sidebar-light-menu-color, 5%);
                        color: $sidely-blue !important;
                    }
                    .menu-icon {
                        display: none;
                    }
                    .menu-icon-active {
                        display: none;
                    }
                    .menu-icon-hover {
                        display: block;
                    }
                }
            }
            &.active {
                >.nav-link {
                    color: $sidebar-light-menu-active-color;
                    background: $sidebar-light-menu-active-bg;
                    // box-shadow: inset 5px 0 0 0 $sidebar-light-menu-active-color !important;
                    .menu-title {
                        color: #ffffff !important;
                    }
                    // i {
                    //   color: inherit;
                    // }
                    .menu-icon {
                        display: none !important;
                    }
                    .menu-icon-active {
                        display: block !important;
                    }
                    .menu-icon-hover {
                        display: none !important;
                    }
                }
            }
            &.nav-profile {
                .nav-link {
                    @include display-flex;
                    @include flex-direction(column);
                    height: auto;
                    .user-wrapper {
                        @include display-flex;
                        margin-bottom: 30px;
                        .profile-image {
                            width: 40px;
                            height: 40px;
                            img {
                                @include border-radius(100%);
                                max-width: 100%;
                            }
                        }
                        .text-wrapper {
                            margin-left: 15px;
                            .rtl & {
                                margin-left: 0;
                                margin-right: 15px;
                            }
                            .profile-name {
                                font-weight: 500;
                                margin-bottom: 8px;
                            }
                            .designation {
                                margin-right: 3px;
                            }
                        }
                    }
                }
            }
        }
        &:not(.sub-menu) {
            >.nav-item {
                &:hover {
                    &:not(.nav-profile) {
                        >.nav-link {
                            background: $sidebar-light-menu-hover-bg;
                            color: $sidebar-light-menu-hover-color;
                        }
                    }
                }
            }
        }
        &.sub-menu {
            margin-bottom: 0;
            padding: $sidebar-submenu-padding;
            .nav-item {
                .nav-link {
                    color: $sidebar-light-submenu-color;
                    padding: $sidebar-submenu-item-padding;
                    font-size: $sidebar-submenu-font-size;
                    line-height: 1;
                    height: auto;
                    position: relative;
                    &.active {
                        color: $sidebar-light-menu-active-color;
                        background: transparent;
                        // &:before {
                        //   background: #000;
                        //   content: '';
                        //   width: 5px;
                        //   height: 35px;
                        //   position: absolute;
                        //   left: -32px;
                        //   top: 0;
                        // }
                    }
                }
                &:hover {
                    >.nav-link {
                        background: $sidebar-light-submenu-hover-bg;
                        color: $sidebar-light-submenu-hover-color;
                        &:before {
                            background: $sidebar-light-submenu-hover-color;
                        }
                    }
                }
            }
        }
    }
    .profile-sidebar {
        text-align: center;
        .profile-image {
            background-color: #f2b42a;
            width: 80px;
            height: 80px;
            border-radius: 40px;
            margin: 0 auto;
        }
        .text-initial {
            font-size: 24px;
            color: #ffffff;
            font-weight: bold;
            padding-top: 20px;
        }
        .text-user {
            font-size: 16px;
            color: #ffffff;
            font-weight: bold;
        }
        .text-role {
            font-size: 8px;
            color: #ffffff;
            font-weight: bold;
        }
        .box-text-profile {
            margin-bottom: 40px;
            margin-top: 10px;
        }
    }
}

//sidebar color variation
.sidebar-dark {
    .sidebar {
        background: $sidebar-dark-bg;
        .nav {
            .nav-item {
                .collapse.show,
                .collapsing {
                    background: $sidebar-dark-menu-active-bg;
                }
                .nav-link {
                    color: $sidebar-dark-menu-color;
                    &[aria-expanded="true"] {
                        background: $sidebar-dark-menu-active-bg;
                    }
                    i {
                        color: $sidebar-dark-menu-icon-color;
                        &.menu-arrow {
                            &:before {
                                color: rgba($sidebar-dark-menu-color, 0.5);
                            }
                        }
                    }
                    &:hover {
                        color: darken($sidebar-dark-menu-color, 5%);
                    }
                }
                &.nav-profile {
                    .profile-name {
                        .name {
                            color: $sidebar-dark-profile-name-color;
                        }
                        .designation {
                            color: $sidebar-dark-profile-title-color;
                        }
                    }
                    .notification-panel {
                        &:before {
                            background: $sidebar-dark-profile-name-color;
                        }
                        >span {
                            background: $sidebar-dark-menu-active-bg;
                            i {
                                color: color(gray-light);
                            }
                        }
                    }
                }
                &.active {
                    >.nav-link {
                        color: $sidebar-dark-menu-active-color;
                    }
                }
                .sidebar-sticker {
                    background: $sidebar-dark-menu-active-bg;
                }
            }
            &:not(.sub-menu) {
                >.nav-item {
                    &:hover {
                        &:not(.nav-profile) {
                            >.nav-link {
                                background: $sidebar-dark-menu-hover-bg;
                                color: #ffffff;
                            }
                        }
                    }
                }
            }
            &.sub-menu {
                .nav-item {
                    .nav-link {
                        color: $sidebar-dark-submenu-color;
                        &.active {
                            color: $sidebar-dark-menu-active-color;
                            &:before {
                                background: $sidebar-dark-menu-active-color;
                            }
                        }
                    }
                    &:hover {
                        >.nav-link {
                            background: $sidebar-dark-submenu-hover-bg;
                            color: $sidebar-dark-submenu-hover-color;
                            &:before {
                                background: $sidebar-dark-submenu-hover-color;
                            }
                        }
                    }
                }
            }
        }
    }
}


/* style for off-canvas menu*/

// @media screen and (max-width: 991px) {
//     .sidebar-offcanvas {
//         position: fixed;
//         // max-height: calc(100vh - #{$navbar-height});
//         // top: $navbar-height;
//         max-height: calc(100vh);
//         top: 0;
//         bottom: 0;
//         overflow: auto;
//         left: -$sidebar-width-lg;
//         -webkit-transition: all 0.25s ease-out;
//         -o-transition: all 0.25s ease-out;
//         transition: all 0.25s ease-out;
//         &.active {
//             right: 0;
//         }
//     }
//     .sidebar-toggle {
//         .sidebar-offcanvas {
//             position: fixed;
//             max-height: calc(100vh);
//             top: 0;
//             bottom: 0;
//             left: 0;
//             -webkit-transition: all 0.25s ease-out;
//             -o-transition: all 0.25s ease-out;
//             transition: all 0.25s ease-out;
//             z-index: 99999;
//         }
//         .sidebar {
//             width: 222px;
//             .page-header {
//                 small {
//                     display: block;
//                 }
//             }
//             .img-title {
//                 display: none;
//             }
//             .nav {
//                 overflow: hidden;
//                 .nav-item {
//                     margin: 0 20px !important;
//                     .nav-link {
//                         .menu-title {
//                             display: block;
//                         }
//                     }
//                     &:hover {
//                         width: 222px;
//                         z-index: 10000;
//                         .nav-link {
//                             .menu-title {
//                                 display: block;
//                             }
//                         }
//                     }
//                 }
//             }
//         }
//         .main-panel {
//             width: 100%;
//             height: 100%;
//         }
//     }
//     .menu_header_color {
//         padding: 16px 22px !important;
//         display: flex !important;
//     }
// }

.nav {
    .nav-item.active {
        .collapse.show>.sub-menu.nav {
            .nav-item {
                .nav-link {
                    color: #fff !important;
                }
                a.nav-link.active {
                    position: relative;
                    // &::after {
                    //   content: '';
                    //   right: 26px;
                    //   position: absolute;
                    //   width: 6px;
                    //   height: 6px;
                    //   background: #fff;
                    //   border-radius: 50%;
                    // }
                }
            }
        }
        a.nav-link.active {
            position: relative;
            // &::after {
            //   content: '';
            //   right: 26px;
            //   position: absolute;
            //   width: 6px;
            //   height: 6px;
            //   background: #fff;
            //   border-radius: 50%;
            // }
        }
    }
}

// for sidebar fixed
.sidebar {
    position: fixed;
    height: 100%;
    left: 0;
    .nav {
        max-height: calc(100% - 20px);
        overflow-y: scroll;
        &::-webkit-scrollbar {
            width: 4px;
            background: transparent;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #d8d8d8;
        }
    }
}

.main-panel {
    position: absolute;
    right: 0;
    //background-color: #f5f6f8;
    height: 100%;
}

.responsive-togggler {
    padding: 16px 22px;
    &:focus {
        outline: none;
    }
}

@media screen and (min-width: 991px) {
    .responsive-togggler {
        display: none !important;
    }
}

// @media screen and (max-width: 991px) {
//     .sidebar {
//         width: 0;
//         max-height: none;
//     }
//     .main-panel {
//         position: relative;
//     }
//     // .sidebar-toggle{
//     //   .sidebar{
//     //     width:
//     //   }
//     // }
// }

.sidebar .nav .nav-item.active>.nav-link {
    border-radius: 5px !important;
}

.sidebar .nav .nav-item:hover {
    // margin: 0 20px !important;
    border-radius: 5px !important;
}

.logo-side {
    height: 36px;
    margin-top: -8px;
}

.icon {
    &:focus {
        outline: none;
    }
}

.menu-tooltip {
    background-color: #00b2ff !important;
    &.place-left {
        &:after {
            border-left-color: #00b2ff !important;
            border-left-style: solid !important;
            border-left-width: 6px !important;
        }
    }
}