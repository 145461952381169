.listing-image {
  height: 150px;
  margin-bottom: 30px;
  border-radius: 10px !important;
  .card-body {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;

    img {
      max-height: 129px;
      max-width: 129px;
      min-height: 110px;
      min-width: 110px;
    }
  }
}

.media-content {
  .media-list {
    padding: 20px 0;
    border-bottom: 1px solid #f2f2f2;
    .listing-image {
      height: 80px;
      width: 80px;
      margin-bottom: 0;
      .card-body {
        img {
          max-height: 54px;
          max-width: 54px;
          min-height: 51px;
          min-width: 51px;
        }
      }
    }
    .form-group {
      label {
        font-weight: bold;
        margin-bottom: 10px;
      }
      .form-control {
        padding: 12px 16px;
        height: 45px;
        border-radius: 5px;
        &:focus {
          border: 1px solid #7f3f98;
        }
      }
    }
  }
  .media-content-wrapper {
    min-height: 400px;
    .media-img {
      max-height: 129px !important;
      max-width: 129px;
      min-height: 120px;
      min-width: 120px;
    }
  }
}

.ant-radio-checked .ant-radio-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  -webkit-transform: rotate(45deg) scale(1) translate(-50%, -50%);
  -ms-transform: rotate(45deg) scale(1) translate(-50%, -50%);
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  -webkit-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}

.ant-radio-group {
  .row {
    .product-wrapper {
      display: flex;
      position: relative;
      border: 1px solid #e0e0e0;
      border-radius: 10px;
      margin: 10px;
      padding: 10px;
      width: 150px;
      height: 150px;
      .ant-radio-wrapper {
        position: absolute;
        .ant-radio {
          &:hover {
            border-color: #544397;
          }
          .ant-radio-inner {
            width: 25px;
            height: 25px;
            border-radius: 50%;
            border-color: none;
            &::after {
              left: 35%;
              top: 47%;
              width: 5.714286px;
              height: 9.14285714px;
              border-radius: 0;
              background: transparent;
            }
            &:hover {
              border-color: #544397 !important;
            }
          }
        }
        .ant-radio-checked .ant-radio-inner {
          border: none !important;
          background-color: #544397 !important;
        }
      }
      img {
        cursor: pointer;
        object-fit: contain;
        width: 100%;
        max-height: 100%;
      }
    }
  }
}

.imgSalesteam{
  height: 60px;
  width: 60px;
}