.dashboard-container {
  margin-top: 7.5em;
}

.positionButton {
  position: absolute;
  top: 8px;
  right: 31px;
}

.btn-edit-modal {
  border: none;
  background-color: transparent;
}

.cardPercented {
  border-radius: 4px;
  // width: calc(100vh - 0.5em);
}

.cardDashboard {
  height: 320px !important;
}

.imgMounth {
  width: 24px;
  height: 24px;
}

.filterMount {
  height: 95% !important;
}

.dropdownMenuMounth {
  top: 5px !important;
  left: -95px !important;
  width: 200px !important;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
}

.dropdownMenuUser {
  top: 5px !important;
  left: -65px !important;
  width: 200px !important;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
}

.dropdownItemMounth button:hover {
  background: transparent !important;
  color: #00b2ff !important;
}

.btnToggle :active {
  background-color: transparent !important;
}

.dropdownMenuManagement {
  left: -18px !important;
  width: 200px !important;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
}

.dropdownMoveDashboard {
  left: -35px !important;
  width: 212px;
  filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.1));
  border-radius: 4px;
  z-index: 1;
  // &::before {
  //   content: '';
  //   position: absolute;
  //   top: -7px;
  //   right: 10%;
  //   border-width: 5px;
  //   border-style: solid;
  //   border-color: transparent transparent #555 transparent;
  //   filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.1));
  // }
}

.containerInput input[type='radio']:checked + span:after {
  background: #00b2ff;
  border-radius: 50px;
  content: ' ';
  height: 13px;
  width: 13px;
  left: 20px;
  position: absolute;
}

.decrChart .point {
  border-radius: 50px;
  height: 8px;
  width: 8px;
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: #74dea5 !important;
  background-color: #74dea5 !important;
}

.boxShadow {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
}

p.textLimit {
  white-space: nowrap;
  width: 99px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.titleLimit {
  white-space: nowrap;
  width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.textLimitSo {
  white-space: nowrap;
  width: 140px;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 0.82rem;
}

#containerChart {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 10px;
  width: 10%;
  height: 240px;
  background-color: #fff;
  overflow: hidden;
}

.recharts-wrapper {
  .recharts-surface {
    width: 95% !important;

    .recharts-cartesian-grid {
      width: 95% !important;
    }
  }
}

.Linebox-shadow {
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.15);
}

.boxSearch {
  width: 100%;
  min-height: 0px;
  max-height: 200px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
}

.searchDashboard {
  .scrollSearch::-webkit-scrollbar {
    width: 10px;
    background: #f3f3f3 !important;
    border-radius: 5px;
  }

  .scrollSearch::-webkit-scrollbar-track {
    // border: 1px solid rgba(218, 218, 218, 0.42);
    border-radius: 5px;
  }

  .scrollSearch::-webkit-scrollbar-thumb {
    background: $sidely-blue !important;
    border-radius: 5px;
  }

  .titleLimit {
    transition: none;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.kanbanDashboard {
  .scrollKanban::-webkit-scrollbar {
    width: 10px;
    background: transparent !important;
  }

  .scrollKanban::-webkit-scrollbar-thumb {
    background: #fff !important;
  }
}

.dashboard-add-box {
  border: 1px dashed #e6e6e6;
  border-radius: 4px;
  height: 320px;
}

.dashboard-add-modal {
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  width: calc(100% - 50px);
  height: calc(100% - 6.5rem);
  top: 6.5rem;
  .modal-content {
    width: 43%;
    height: 100%;
    overflow-y: scroll;
    margin-left: auto;
    padding: 1rem;
    background-color: #ffffff;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
    border-radius: 4px 0 0 4px;
  }
  .modal-search {
    width: 25% !important;
    overflow-y: auto;
    margin-left: 0px !important;
    border-radius: 0 4px 4px 0 !important;
  }
}