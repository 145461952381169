.height-progressbar{
    height: 15px !important;
}
.progressbar-enform1 {
  .progress-bar {
      background-color: #7E3932;
  }
}

.progressbar-enform2 {
  .progress-bar {
    background-color: #c04a40;
  }
}
.progressbar-enform3 {
  .progress-bar {
    background-color: #F45A4C;
  }
}
.progressbar-enform4 {
  .progress-bar {
    background-color: #F87942;
  }
}
.progressbar-enform5 {
  .progress-bar {
    background-color: #FFA340;
  }
}
.progressbar-enform6 {
  .progress-bar {
    background-color: #FEC940;
  }
}
.progressbar-enform7 {
  .progress-bar {
    background-color: #FFEA2C;
  }
}
.progressbar-enform8 {
  .progress-bar {
    background-color: #C8ED34;
  }
}
.progressbar-enform9 {
  .progress-bar {
    background-color: #87F16D;
  }
}
.progressbar-enform10 {
  .progress-bar {
    background-color: #2ED47A;
  }
}
.progressbar-enform11 {
  .progress-bar {
    background-color: #14AB59;
  }
}

