.hover-opt{
    position: absolute;
    background: #323C47;
    color: #FFF;
    font-size: 12px;
    width: 15rem;
    border-radius: 6px;
    padding: 10px;
    z-index: 9;
    left: 3rem;
    .bg-btn-opt{
        background: #f5f6f8;
        border-radius: 4px;
        color: #254153;
        padding: 8px;
        font-size: 8px;
        margin-bottom: 10px;
    }
    .btn-opt{
        width: 40%;
        margin: 10px;
        background: #FFF;
        border-radius: 4px;
        padding: 4px;
        font-size: 11px;
        
    }
    .btn-no{
        border: 1px solid #F7685B;
        color: #F7685B;
    }
    .btn-yes{
        border: 1px solid #2ED47A;
        color: #2ED47A;
    }
    .btn-facing{
        color: #fff;
        width: 15%;
        height: 1.5rem;
        border: none;
    }
    .min{
        border-radius: 4px 0 0 4px;
        background: #f7685b;
    }
    .plus{
        border-radius: 0 4px 4px 0;
        background: #2ED47A;
    }
    .input-facing{
        width: 70%;
        border: 1px solid #e8e8e8;
        text-align: center;
    }
    select{
        width: 95%;
        padding: 5px;
        border-radius: 4px;
        border: 1px solid #e8e8e8;
    }
}

.btn-enForm{
    font-size: 12px;
width: 100%;
padding: 5px;
border-radius: 4px;
}

.btn-view{
background: #fff;
border: 1px solid #00b2ff;
color: #00b2ff;
}

.btn-setup {
  background: #00ce68;
  border: 1px solid #FFF;
  color: #FFF;
  &:hover {
      background-color: #00a855;
  }
}

.btn-installed{
   background: #e65251;
   border: 1px solid #fff;
   color: #fff;
   &:hover {
       background-color: #e13130;
   }
}

.btn-previous{
    background: #FFF;
    color: #00b2ff;
}
