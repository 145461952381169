/* Forms */
.input-group-append,
.input-group-prepend {
  background: color(white);
  color: $input-placeholder-color;
  width: auto;
  border: none;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;

  .input-group-text {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background: transparent;
    border-color: $border-color;
  }
}

.form-builder {
  &:disabled {
    color: black;
  }
}

.form-control {
  border: 1px solid $border-color;
  // font-family: $type-1;
  font-size: $input-font-size;
  padding: $btn-padding-y 0.75rem;
  line-height: 14px;
  font-weight: 300;

  &.form-control-lg {
    padding: $input-btn-padding-y-lg 0.75rem;
  }

  &.form-control-sm {
    padding: $input-btn-padding-y-sm 0.75rem;
  }

  &.focus-error {
    border-color: red;
  }
}

.focus-error-select {
  border: 1px solid red;
  border-radius: 5px;
}

select {
  &.form-control {
    padding: 0.4375rem 0.75rem;
  }
}

.form-group {
  label {
    font-size: $default-font-size;
    line-height: 1;
    margin-bottom: 4px;
    // vertical-align: top;
  }

  &.has-danger {
    .form-control {
      border-color: theme-color(danger);
    }
  }

  .file-upload-default {
    visibility: hidden;
    position: absolute;
  }

  .file-upload-info {
    background: transparent;
  }
}

.form-error {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 11px;
  color: #dc3545;
}

// custom
.search-v1 {
  .form-control {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-left: none !important;
    padding: $btn-padding-y 0px;
    border: 1px solid rgba(158, 172, 192, 0.23);
    box-sizing: border-box;
    height: 37px;
    color: #4f4f4f;
    font-size: 12px;
    line-height: 14px;

    &:focus {
      border-color: $sidely-blue;

      + .input-group-prepend {
        border-color: #4f4f4f;
      }
    }

    ::placeholder {
      color: #90a0b7 !important;
    }
  }

  .input-group-prepend {
    &:focus {
      outline: none;
    }

    .group-text-map {
      padding: 0.5rem 1rem !important;
    }

    .input-group-text {
      height: 37px;
      border-left: none;
      border-top-left-radius: 4px !important;
      border-bottom-left-radius: 4px !important;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      transition-property: border-color, box-shadow;
      transition-duration: 0.15s, 0.15s;
      transition-timing-function: ease-in-out, ease-in-out;
      transition-delay: 0s, 0s;
      border: 1px solid rgba(158, 172, 192, 0.23);
      box-sizing: border-box;
      color: #90a0b7;
    }
  }

  &.focused {
    .input-group-prepend {
      span {
        border-color: $sidely-blue;
        // background: $blueish;
        // color: #fff;
      }
    }

    .form-control {
      border-color: $sidely-blue;
      // background: $blueish;
      color: black;

      ::placeholder {
        color: #fff;
      }

      ::-ms-input-placeholder {
        color: #fff;
      }
    }
  }

  ::placeholder {
    color: #90a0b7;
    font-size: 12px;
    line-height: 14px;
    opacity: 1;
    /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #4f4f4f;
    font-size: 12px;
    line-height: 14px;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #4f4f4f;
    font-size: 12px;
    line-height: 14px;
  }
}

.search-v2 {
  padding: 10px;
  background-color: #f7f7f7;

  .form-control {
    border-radius: 50px;
    border: 1px solid rgba(158, 172, 192, 0.23);
    box-sizing: border-box;
    height: 37px;
    color: #4f4f4f;
    font-size: 12px;
    line-height: 14px;

    &:focus {
      border-color: $sidely-blue;

      + .input-group-prepend {
        border-color: #4f4f4f;
      }
    }

    ::placeholder {
      color: #90a0b7 !important;
    }
  }
}

.search-v3 {
  padding: 10px;

  .form-control {
    border: none;
    border-bottom: 1px solid rgba(158, 172, 192, 0.23);
    box-sizing: border-box;
    height: 37px;
    color: #4f4f4f;
    background-color: transparent;
    font-size: 12px;
    line-height: 14px;

    &:focus {
      border-color: $sidely-blue;

      + .input-group-prepend {
        border-color: #4f4f4f;
      }
    }

    ::placeholder {
      color: #90a0b7 !important;
    }
  }
}

.show-password {
  span {
    background-color: rgb(232, 240, 254) !important;
    border-right: none;
    border-top: none;
    i {
      &:focus {
        outline: none;
      }
    }
  }
}

.password-field {
  .input-group {
    input {
      border-right: none;
    }

    &.focused {
      .reset-btn {
        .input-group-text {
          border-color: #80bdff;
        }
      }
    }

    &.password-error {
      .reset-btn {
        .input-group-text {
          border-color: red;
        }
      }
    }
  }

  i {
    &:focus {
      outline: none;
    }
  }
}

.upload-placeholder {
  font-size: 32px;
}

.form-group .input-custom .form-control {
  background-color: #ffffff !important;
  color: gray;
}

.mini-box {
  .ant-upload {
    margin: 0 !important;

    span.ant-upload {
      padding: 0;
    }

    width: 35px !important;
    height: 35px !important;

    img {
      width: 30px;
      height: 30px;
    }

    .anticon {
      vertical-align: 0;
    }
  }
}

.large-box {
  .ant-upload {
    span.ant-upload {
      padding: 0;
    }

    object-fit: contain !important;
    width: 150px !important;
    height: 150px !important;

    img {
      width: 150px;
      height: 150px;
    }
  }
}

.upload-card {
  position: relative;

  .btn-remove {
    position: absolute;
    right: 14px;
    top: 5px;
    background: #fff;
    border-radius: 50%;
    color: red;
  }

  &.mini-up {
    .btn-remove {
      right: 0px;
    }
  }
}

.input-cat {
  input {
    height: 45px;
    border-radius: 5px;
  }
}

.upload-media-holder {
  object-fit: contain !important;
  display: table;
  margin-right: 8px;
  margin-bottom: 8px;
  text-align: center;
  vertical-align: top;
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
  cursor: pointer;
  -webkit-transition: border-color 0.3s ease;
  transition: border-color 0.3s ease;
  width: 150px !important;
  height: 150px !important;
  box-sizing: border-box;
  outline: 0;

  &:hover {
    border-color: #1890ff;
  }

  .media-inside {
    display: table-cell;
    width: 100%;
    height: 100%;
    padding: 3px;
    text-align: center;
    vertical-align: middle;

    img {
      object-fit: contain;
    }
  }
}

.mini-box {
  .btn-remove {
    top: 0;
  }

  .upload-media-holder {
    width: 32px !important;
    height: 32px !important;
    margin-right: 3px;

    .media-inside {
      padding: 1px;

      img {
        width: 30px;
        height: 30px;
        object-fit: contain;
      }

      .anticon {
        vertical-align: 0;
      }
    }
  }
}

.form-edit-user {
  input {
    &:disabled {
      background: #fff;
      color: #333;
      font-weight: bold;
      padding: 0;
      border: 0;
    }
  }
}

.form-field-array {
  list-style: none;
  padding-left: 0px;

  .icon-plus {
    color: #2ed47a;
    font-size: 30px;
  }

  .icon-minus {
    color: #f7658b;
    font-size: 30px;
  }

  .iconButton {
    color: #fff;
    font-size: 13px;
  }

  .bgButtonArray {
    height: 30px;
    width: 30px;
    // background-color: #F7658B;
    border-radius: 50%;
  }

  button {
    border: none;
    background: none;
  }
}

.form-filter-opportunity {
  min-width: 180px;
  height: 38px;
  background: white;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  margin-right: 10px;
  padding: 0px 20px 0px 10px;

  p,
  .toggler {
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #495057;
    letter-spacing: normal;
    margin: 0px 0px 0px 5px !important;
  }

  .toggler {
    background: transparent;
    border: none;
  }
}

.form-filter-option {
  background: white;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  margin-right: 10px;
  padding: 0px 20px 0px 0px;
  min-width: 65px;
  p,
  .toggler {
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #495057;
    letter-spacing: normal;
    margin: 0px 0px 0px 5px !important;
  }

  .toggler {
    background: transparent;
    border: none;
  }
}

.react-select-force-width {
  width: 150px !important;
  border-color: #f2f2f2;
  font-size: 12px;
}

.react-select-container {
  .btn-group {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border: 1px solid #ebeff2;
    border-width: 1px 1px 1px 0;
  }

  .btn-secondary {
    &:active {
      background-color: transparent !important;
      border-color: #ebeff2 !important;
    }
  }

  .dropdown-toggle {
    &:after {
      border-bottom-color: hsl(0, 0%, 80%);
      border-top-color: hsl(0, 0%, 80%);
    }
  }
}

.input-group {
  // &.input-group:hover {
  //   border: 1px solid;
  //   border-color: #00b2ff;
  //   border-radius: 5px;
  // }
  .react-select-container {
    width: calc(100% - 38px);
  }

  .input-group-prepend {
    border-radius: 5px;

    .input-group-text {
      border-radius: 5px 0 0 5px;
      border-right-color: transparent;
      // &:hover {
      //   border-color: transparent !important;
      // }
    }
  }

  .react-select__control {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;

    &.react-select__control--is-focused {
      border-color: #f2f2f2 !important;
      box-shadow: none;
    }

    &:hover {
      border-color: #f2f2f2 !important;
    }
  }
}

.react-datepicker-wrapper {
  width: 100%;
}

.rc-input-number.form-control {
  padding: 0;
  border: none;
  input {
    width: 100%;
    height: 100%;
    border: 1px solid #ebeff2;
    font-size: 0.75rem;
    padding: 0.56rem 0.75rem;
    line-height: 14px;
    font-weight: 300;
    border-radius: 5px;
  }
}

// textarea#note {
//   width: 100%;
//   box-sizing: border-box;
//   // direction: rtl;
//   display: block;
//   max-width: 100%;
//   // line-height: 1.5;
//   padding: 15px 15px 30px;
//   border-radius: 3px;
//   border: 1px solid #F7E98D;
//   // font: 13px Tahoma, cursive;
//   transition: box-shadow 0.5s ease;
//   box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
//   // font-smoothing:subpixel-antialiased;
//   // background: linear-gradient(#F9EFAF, #F7E98D);
//   // background: -o-linear-gradient(#F9EFAF, #F7E98D);
//   // background: -ms-linear-gradient(#F9EFAF, #F7E98D);
//   // background: -moz-linear-gradient(#F9EFAF, #F7E98D);
//   // background: -webkit-linear-gradient(#F9EFAF, #F7E98D);
// }

.disabled-hyperlink {
  &:hover {
    border: solid 0.5px #f2f2f2 !important;
  }
}

.ant-upload.ant-upload-disabled:hover {
  border-color: #f2f2f2;
}
