.pagination {
  display: flex;
  .page-item {
    color: $pagination-color;
    &.active .page-link {
      z-index: 1;
      color: $pagination-active-color;
      background-color: $pagination-active-bg;
      border-color: $pagination-active-border-color;
    }
  }
}

.pagination-cs {
  color: #333;
  .box {
    border: 1px solid #f2f2f2;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 5px;
    margin-left: 5px;
  }
  .btn {
    color: #7f3f98;
    &:disabled {
      color: #bdbdbd;
    }
  }
}
