.modal-header-map {
    >.modal-title {
        width: 100%;
        color: white;
    }
}

.map-position {
    position: fixed !important;
}

.map-modal {
    top: -100px;
    width: 60%;
}

.left100 {
    left: 30% !important;
}

.leftModal {
    left: 10% !important;
}

.map-modal-list {
    top: 0px;
    width: 75%;
    left: 32%;
}

.backdrop-map-modal {
    background-color: transparent !important;
}

.filter-modal-span {
    display: flex;
    z-index: 1;
    .box-filter-new {
        width: 19vw;
        height: 100vh;
        background-color: #ffffff;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        overflow-y: scroll;
        /* Hide scrollbar for IE, Edge and Firefox */
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */
        /* Hide scrollbar for Chrome, Safari and Opera */
        &::-webkit-scrollbar {
            display: none;
        }
    }
    .btn-filter-modal {
        background-color: #00b2ff;
        box-shadow: 5px 0px 7px rgba(0, 0, 0, 0.24);
        padding: 0.6rem 0.15rem;
        border-radius: 0;
        height: 40px;
        margin-top: 40vh;
    }
}

.filter-modal {
    position: absolute;
    top: 55px;
    height: calc(100% - 55px);
    display: flex;
    align-items: center;
    .box-filter-modal {
        height: 100%;
        width: 325px;
        background-color: #ffffff;
        box-shadow: 10px 0px 5px -7px rgba(0, 0, 0, 0.1);
        overflow-y: scroll;
        /* Hide scrollbar for IE, Edge and Firefox */
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */
        /* Hide scrollbar for Chrome, Safari and Opera */
        &::-webkit-scrollbar {
            display: none;
        }
        .nav-tabs {
            border-bottom: 0;
            // width: 275px;
            .nav-item {
                width: 50%;
                text-align: center;
                .nav-link {
                    color: #c7c7c7;
                    border-radius: 0;
                    background-color: #fafafa;
                    box-shadow: inset 0px 0px 8px rgba(0, 0, 0, 0.06);
                    padding: 5px;
                    &.active {
                        color: rgba(37, 65, 83, 0.8);
                        background-color: #ffffff;
                        box-shadow: none;
                        border-color: transparent;
                    }
                    &:hover {
                        border-color: transparent;
                    }
                }
            }
        }
        .nav-pin {
            .nav-item {
                width: 100%;
                text-align: left;
                .nav-link {
                    color: #323c47;
                    background-color: transparent;
                    box-shadow: none;
                    border-top: 1px solid #ebeff2;
                    border-bottom: 1px solid #ebeff2;
                    padding: 1.25rem 1.5rem;
                    position: relative;
                    &.active {
                        color: #00b2ff;
                        border-top-color: #ebeff2;
                        border-bottom-color: #ebeff2;
                    }
                    &:hover {
                        border-top-color: #ebeff2;
                        border-bottom-color: #ebeff2;
                    }
                    .active-block {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 10px;
                        height: 100%;
                        background-color: #00b2ff;
                    }
                }
            }
        }
        .ul-pin {
            list-style: none;
            li {
                line-height: 0.6;
                &::before {
                    content: "\2022";
                    display: inline-block;
                    width: 1.5rem;
                    font-size: 2rem;
                    vertical-align: middle;
                    margin-bottom: 0.25rem;
                }
                &.grey {
                    &::before {
                        color: #dadada;
                    }
                }
                &.green {
                    &::before {
                        color: #2ed47a;
                    }
                }
                &.yellow {
                    &::before {
                        color: #ffea2c;
                    }
                }
                &.red {
                    &::before {
                        color: #ff7669;
                    }
                }
                &.blue {
                    &::before {
                        color: #00b2ff;
                    }
                }
            }
        }
        .color-box {
            width: 37px;
            padding: 2px 7px;
            font-size: 12px;
            color: #323c47;
            border: 1px solid #ebeff2;
            border-radius: 5px;
        }
    }
    .btn-filter-modal {
        background-color: #00b2ff;
        box-shadow: 2px 0px 7px rgba(0, 0, 0, 0.15);
        padding: 2rem 0.15rem;
        border-radius: 3px;
        z-index: 5;
    }
}

.bg-cluster {
    position: relative;
}

.cluster-marker {
    position: absolute;
    color: #fff;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    background: #6f7375;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.text-cluster {
    background: #254153;
    min-width: 25px;
    border-radius: 50%;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.text-tooltips {
    right: -65px;
    transform: translate(-40%, -265%);
    background-color: #fff;
    color: #4f4f4f;
    font-size: 12px;
    text-align: center;
    border-radius: 6px;
    padding: 5px 15px;
    width: max-content;
    border: solid 0.3px rgb(177, 177, 177);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
    -o-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
}

.description-tooltips {
    transform: translate(-105%, -100%);
    background-color: #fff;
    color: #4f4f4f;
    // font-size: 12px;
    text-align: center;
    border-radius: 6px;
    padding-top: 5px;
    padding-bottom: 5px;
    width: 240px;
    // border: solid 0.3px rgb(177, 177, 177);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
    -o-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
}

.font-date {
    font-size: 10px;
    padding: 10px;
}

.pie-width {
    width: 25px;
}

.circle {
    width: 30px;
    height: 30px;
    filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.35));
    border-radius: 100px;
}

.circle-reporting {
    width: 30px;
    height: 30px;
    border-radius: 100px;
}

.circle-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block;
    background-color: #69d0f2;
    border: solid 0.1px rgb(177, 177, 177);
    box-shadow: 0px 0.5px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 0.5px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0px 0.5px rgba(0, 0, 0, 0.25);
    -o-box-shadow: 0px 0.5px rgba(0, 0, 0, 0.25);
}

.dot {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
}