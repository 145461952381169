/* Badges */
.badge {
  border-radius: 0.25rem;
  font-size: 0.65rem;
  font-weight: initial;
  line-height: 1;
  padding: 0.2rem 0.3rem;
  font-family: $type-1;
  font-weight: 600;
  &:empty {
    display: inline-block;
    min-width: 10px;
    min-height: 10px;
    padding: 0;
    margin-right: 10px;
    @include border-radius(100%);
    .rtl & {
      margin-left: 10px;
      margin-right: 0;
    }
  }

  &.badge-pill {
    border-radius: 10rem;
  }

  &.badge-fw {
    min-width: 70px;
  }

  &.badge-lg {
    padding: 0.4rem 0.5rem;
  }
}

.badge {
  &.badge-indicator {
    color: #fff;
    border-radius: 3px;
    padding: 7px 10px;
    &.success {
      background: #00b140;
    }
    &.onprogress {
      background: #7f3f98;
    }
    &.cancel {
      background: #bdbdbd;
    }
  }
}
/*Badge variations*/
@each $color, $value in $theme-colors {
  .badge-#{$color} {
    @include badge-variations($value);
  }
}
/*Badge inverse variations*/
@each $color, $value in $theme-colors {
  .badge-inverse-#{$color} {
    @include badge-inverse-variations($value);
  }
}
/*Badge outlined variations*/
@each $color, $value in $theme-colors {
  .badge-outline-#{$color} {
    @include badge-outline-variations($value);
  }
}

.opportunity-badge {
  position: absolute;
  z-index: 0.5;
  border-radius: 50%;
  width: 26px;
  height: 26px;
  right: 12px;
  bottom: 7px;
}

.thumbnail-circle-1 {
  border-radius: 50px;
  // width: 24px;
  height: 24px;
}

.no-contact-thumbnail {
  width: 38px;
  height: 38px;
  background: #eaeaea;
  border-radius: 50px;

  span {
    font-size: 24px;
    color: white;
  }
}

.no-contact-thumbnail-big {
  width: 50px;
  height: 50px;
  background: #eaeaea;
  border-radius: 50px;

  span {
    font-size: 26px !important;
    font-weight: 500 !important;
    color: white !important;
    margin-left: 0px !important;
  }
}

.map-pin {
  font-size: 32px;
  color: $sidely-blue;
  background-color: transparent;
  border: none;

  // span {
  //   transform: translate(-150%, -25%);
  //   font-size: 13px;
  //   font-weight: 500;
  //   color: white;
  // }
  // span {
  //   transform: translate(-120%, -25%);
  //   font-size: 13px;
  //   font-weight: 500;
  //   color: white;
  // }
  // span {
  //   transform: translate(-180%, -25%);
  //   font-size: 13px;
  //   font-weight: 500;
  //   color: white;
  // }
}

.expense-status {
  width: 80px;
  line-height: 20px;
  border-radius: 5px;
  color: white;
  text-align: center;

  &.detail {
    line-height: 20px !important;
    font-size: 12px !important;
    margin-left: 25px;
    height: 20px !important;
    width: 120px !important;
  }

  &.bg-in-process {
    background-color: #ffea2c;
  }
  &.bg-processed {
    background-color: #2ed47a;
  }
  &.bg-rejected {
    background-color: #f7685b;
  }
  &.bg-inactive {
    background-color: white;
    border: 1px solid $border-color;
    color: $border-color;
  }
}

.event-status {
  width: 80px;
  line-height: 20px;
  border-radius: 5px;
  color: white;
  text-align: center;

  &.detail {
    line-height: 20px !important;
    font-size: 12px !important;
    margin-left: 25px;
    height: 20px !important;
    width: 120px !important;
  }

  &.bg-to-do {
    background-color: #FFB946;
  }
  &.bg-completed {
    background-color: #2ED47A;
  }
  &.bg-cancelled {
    background-color: #F7685B;
  }
}

.detail-checkbox {
  color: white;
  font-size: 12px;
  height: 16px;
  width: 16px;
  padding: 2px;
  border-radius: 3px;
  &.true {
    background-color: $sidely-blue;
  }

  &.false {
    background-color: white;
    border: 1px solid $border-color;
  }
}
