/* Dropdowns */

.dropdown-menu {
    font-size: 12px;
    border-radius: 5px;
    min-width: 140px;
    padding: 0;
    overflow: hidden;

    // top: 10px !important;
    // left: -60px !important;
    button {
        padding: 10px 15px;

        a {
            color: #4f4f4f;
        }

        &:hover {
            background: $sidely-blue;
            color: #fff;

            a {
                color: #fff !important;
                text-decoration: none;
            }

            img {
                filter: brightness(0) invert(1);
            }
        }
    }

    .droptdownHide .Select-arrow-zone {
        display: none;
    }

    .droptdownHide .Select-menu-outer {
        display: none;
    }

    .dropdown-divider {
        margin: 0;
    }

    &.dropdown-chat {
        transform: translate3d(-85px, 35px, 0px) !important;
    }
}

.dropdown-menu-2 {
    background: white;
    font-size: 12px;
    border-radius: 5px;
    min-width: 140px;
    padding: 0;
    overflow: hidden;
    position: fixed !important;
    box-shadow: 0px 1px 5px #cfcfcf;
    border: 1px solid rgba(0, 0, 0, 0.125);

    // top: 10px !important;
    // left: -60px !important;
    button {
        padding: 10px 15px;

        a {
            color: #4f4f4f;
        }

        &:hover {
            background: $sidely-blue;
            color: #fff;

            a {
                color: #fff !important;
                text-decoration: none;
            }

            img {
                filter: brightness(0) invert(1);
            }
        }
    }

    .droptdownHide .Select-arrow-zone {
        display: none;
    }

    .droptdownHide .Select-menu-outer {
        display: none;
    }

    .dropdown-divider {
        margin: 0;
    }

    &.dropdown-chat {
        transform: translate3d(-85px, 35px, 0px) !important;
    }
}

.dropdown-item {
    // font-size: 11px !important;
    // font-weight: 300 !important;
    line-height: 20px !important;
    color: #495057;
}

.dropdown-menu-right {
    .nav-link {
        padding: 0 !important;

        .nav-item {
            padding: 10px 15px;

            &.active {
                background: #fff;
                color: #000 !important;
            }
        }
    }
}

.dropdownSelectOption {
    transform: translate3d(-1px, -115px, 0px) !important;
    min-width: 60px !important;
}

.grey-dropdown button:hover {
    background-color: #828282 !important;
}

.dropdown-custom {
    margin: 0px !important;
    padding: 2px !important;
}

.toggler {
    position: relative;

    &:after {
        position: absolute !important;
        color: hsl(0, 0%, 60%);
        right: -10px;
    }
}

.custom-position {
    z-index: 10;
    // transform: translate3d(0px, 10px, 0px) !important;
}

.custom-position-v2 {
    transform: translate3d(-125px, 25px, 0px) !important;
}

.custom-position-create-expense {
    transform: translate3d(-65px, 25px, 0px) !important;
}

.custom-position-logout {
    transform: translate3d(-60px, 35px, 0px) !important;
}

.custom-position-create {
    left: -25px !important;
}

.custom-popper {
    top: 10% !important;
    width: max-content;
}

.popper-position {
    left: -140px !important;
}

.field-position {
    left: -40px !important;
}

.dropdownTransform {
    transform: translate3d(0px, 32px, 0px) !important;
}

.dropdown-item.active,
.dropdown-item:active {
    text-decoration: none;
    color: #fff;
    background-color: #00b2ff;
}

.selectScroll {
    .icon-select {
        position: absolute;
        top: 10px;
        left: 20px;
    }

    .carret-down {
        position: absolute;
        right: 20px;
        top: 10px;
        color: lightgray;
        cursor: pointer;
    }

    .option-menu {
        position: absolute;
        width: 15rem;
        max-height: 10rem;
        z-index: 8;
        right: 10px;
    }
}