.saving-page {
	margin: 20px 50px;
	.nav {
		border-bottom: 1px solid #e1e7f8;
		.nav-item {
			cursor: pointer;
			.nav-link {
				padding: 15px;
				&:hover {
					border-color: #fff;
				}
				&.active {
					border-width: 3px 0;
					border-radius: 0;
					border-color: #fff #fff $primary-amartha;
				}
			}
		}
	}
	.tab-content {
		margin-top: 27px;
		.tab-pane {
			.text-small {
				margin: 5px 0;
			}
			p {
				margin-bottom: 25px;
			}
		}
	}
}
