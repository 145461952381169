//
// Base styles
//

.alert-wrapper {
  //   position: fixed;
  //   z-index: 999;
  //   width: 100%;

  .alert {
    border: none;
    border-radius: 0px;
    .close-btn {
      cursor: pointer;
    }
    &.alert-success {
      color: #fff;
      background: $green;
    }
    &.alert-info {
      color: #fff;
      background: #c8cbdc;
    }
    &.alert-danger {
      color: #fff;
      background: #d64d37;
    }
    &.alert-selected {
      color: #544397;
      background: #f4f3fa;
    }
    .close {
      display: none;
    }
  }
}

.swal2-title,
.swal2-text {
  font-family: $type-1 !important;
}

// .swal2-styled.swal2-confirm {
//   background-color: $sidely-blue !important;
// }

// .swal2-styled.swal2-cancel {
//   background-color: $sidely-red !important;
// }
