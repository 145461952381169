.navbar-subtop {
  margin: 0px;
  padding: 0.5rem;
  background-color: #f9f9fb !important;
  // height: 55px;
  .page-header-title {
    color: #3e5366;
    font-size: 12px;
  }
  .navbar-nav {
    .nav-link {
      line-height: 41px;
      padding: 0 0.5rem;
      color: #3e5366;
      cursor: pointer;
      &:hover {
        background-color: #b2cbe7;
        color: #fff;
      }
    }
    .nav-item {
      margin: 0;
      &.dropdown-views .dropdown-menu {
        left: inherit;
        right: 0;
      }
    }
    &.navbar-nav-right {
      padding-right: 0.5rem;
    }
    .dropdown-menu {
      border-left: 1px solid #ebf0f4;
      border-bottom: 1px solid #ebf0f4;
      border-right: 1px solid #ebf0f4;
      border-top: 0;
      border-radius: 0;
      margin-top: 0;
      padding: 0;
      left: -2px;
      min-width: 105%;
      .dropdown-item {
        color: #3e5366;
        background: #fff;
        padding: 0.5rem 1rem;
        cursor: pointer;
        &:focus,
        &:hover {
          background-color: #b2cbe7;
          color: #fff;
        }
      }
    }
  }

  .box-toolbar-initial {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin-right: 10px;
    color: #ffffff;
    .text-toolbar-initial {
      text-align: center;
      margin-top: 7px;
    }
  }
}

.search-custom-width {
  width: 30%;
}

.nav-item-separator {
  border-top: 0.5px solid #e3e3e3;
  margin: 5px;
}

.toolbar-item-separator {
  border-top: 0.5px solid #e3e3e3;
  margin: 1px;
}