.custom-icon-plus {
  background-color: #e8f8ff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.thumbnail-chat-list {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.list-member {
  position: fixed;
  width: 26%;
  z-index: 1;
  height: calc(100% - 100px) !important;
}

.thumbnail-chat-header {
  // background-color: #e8f8ff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.list-chat {
  position: fixed;
  width: 75%;
  z-index: 1;
  height: calc(100% - 100px) !important;
}

.mesgs {
  float: left;
  padding: 10px 0px 0px 0px;
  width: 100%;
}

.incoming_msg_img {
  display: inline-block;
  width: 6%;
}
.msg-image-icon {
  width: 30px;
}
.received_msg {
  display: inline-block;
  padding: 0 0 10px;
  vertical-align: top;
  width: auto;
}
.received_msg h6 {
  margin-bottom: 0px;
  margin-top: 6px;
  padding-left: 0px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}
.received_withd_msg {
  padding-top: 5px;
  padding-bottom: 10px;
  p {
    color: #646464;
    font-size: 14px;
    margin: 0;
    padding: 10px;
    width: auto;
    max-width: 500px;
    background: #ffffff none repeat scroll 0 0;
    box-shadow: 0px 1px 4px rgba(155, 155, 155, 0.3);
    border-radius: 0px 10px 10px 10px;
  }
}

.received_withd_msg {
  width: 100%;
}

.time_date {
  color: #747474;
  display: block;
  font-size: 12px;
  margin: 8px 0 0;
}

.outgoing_msg {
  overflow: hidden;
  margin: 26px 0 26px;
}
.sent_msg {
  p {
    float: right;
    width: auto;
    max-width: 500px;
    padding: 10px;
    background-color: #00b2ff;
    border-radius: 10px 0px 10px 10px;
    box-shadow: 0px 1px 4px rgba(155, 155, 155, 0.3);
    color: white;
  }
}

.input_msg_write input {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  color: #4c4c4c;
  font-size: 15px;
  min-height: 48px;
  width: 100%;
}

.type_msg {
  border-top: 1px solid #c4c4c4;
  position: relative;
}
.msg_send_btn {
  //background: #05728f none repeat scroll 0 0;
  border: medium none;
  border-radius: 50%;
  color: #c2cfe0;
  cursor: pointer;
  font-size: 17px;
  height: 33px;
  position: absolute;
  right: 0;
  top: 11px;
  width: 33px;
}

.tab_feature_icon_member {
  position: relative;
  p {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;

    /* identical to box height */
    text-align: center;
    color: #c2cfe0;
  }
}

.feature_icon_member {
  border: medium none;
  border-radius: 50%;
  color: #c2cfe0;
  background-color: white;
  cursor: pointer;
  font-size: 17px;
  right: 0;
  top: 11px;
  width: 25%;
}

.messaging {
  padding: 0 0 50px 0;
}

.msg_history {
  height: calc(100vh - 210px);
  overflow-y: scroll;
}

.chat-title {
  //font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 21px;
  color: #323c47;
}

.chat-description {
  //font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;
  color: #323c47;
}

.chat-last-message {
  //font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #323c47;
}

.my-list-chat {
  width: 100%;
  display: flex;
  background-color: white;
  border: 0;
}

.chat-avatar {
  width: 60px;
  height: 60px;
  margin: auto;
  border-radius: 50%;
  object-fit: cover;
}

.chat-container {
  background-color: white;
}

.chat-header {
  padding: 10px 20px;
  border-bottom: 1px solid #e9e9ea;

  .chat-avatar-room {
    width: 55px;
    height: 55px;

    img {
      border-radius: 50%;
      width: 55px;
      height: 55px;
      object-fit: cover;
    }
  }

  .chat-name {
    padding-left: 15px;
  }

  .fa {
    font-size: 14px;
  }

  .btn {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

.chat-overflow {
  overflow-y: auto;
  max-height: calc(100vh - 4em - 160px);
}

.chat-overflow::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

.chat-overflow::-webkit-scrollbar-thumb {
  background-color: #00b2ff;
}

.chat-content {
  padding: 10px 20px;
  min-height: calc(100vh - 4em - 160px);
  align-content: flex-end;

  ::-webkit-scrollbar {
    width: 5px;
    background-color: #f5f5f5;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #00b2ff;
  }

  .chat-opponent,
  .chat-user {
    display: flex;
    align-items: center;
    padding: 7px 0;

    .chat-avatar-user {
      flex-basis: 7%;

      img {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    .chat-message {
      margin: 0;
      padding: 5px;
      flex-basis: 90%;

      .chat {
        box-shadow: 1px 0px 3px rgba(0, 0, 0, 0.25);
      }

      span {
        white-space: normal;
        font-size: 12px;
      }
    }
  }

  .chat-opponent {
    justify-content: flex-start;

    .chat-avatar-user {
      text-align: left;
    }

    .chat-message {
      text-align: left;

      .chat-title {
        display: block;
        padding-bottom: 5px;
      }

      .message {
        border-top-right-radius: 10px;
        border-bottom-left-radius: 10px;
        background-color: white;
        box-shadow: 1px 0px 3px rgba(0, 0, 0, 0.25);
      }

      .time {
        display: block;
        padding-top: 15px;
      }
    }
  }

  .chat-user {
    justify-content: flex-end;

    .chat-avatar-user {
      text-align: right;
    }

    .chat-message {
      text-align: right;

      .chat-title {
        display: block;
        padding-bottom: 5px;
      }

      .message {
        border-top-right-radius: 10px;
        border-bottom-left-radius: 10px;
        background-color: #00b2ff;
        box-shadow: 1px 0px 3px rgba(0, 0, 0, 0.25);
        color: white;
      }

      .time {
        display: block;
        padding-top: 15px;
      }
    }
  }
}

.chat-form {
  padding: 10px 20px;
  border-top: 1px solid #e9e9ea;

  textarea {
    resize: none;
    overflow: hidden;
  }

  .btn {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .fa {
    font-size: 22px;
    color: grey;
  }
}

.chat-empty-container {
  height: calc(100vh - 5em);
  background-color: white;
}

.my-group-container {
  height: calc(100vh - 4em - 490px);
  max-height: calc(100vh - 4em - 490px);
  overflow-y: auto;
  background-color: white;
  align-content: flex-start;
}

.my-group-container::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

.my-group-container::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
}

.image-selector {
  padding: 100px 0;
  width: 100%;
  cursor: pointer;
  color: white;
  background-color: #747474;
  display: block;
  text-align: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.warning-modal {
  text-align: center;

  h5 {
    width: 100%;
  }
}
