.dropzone-ui {
  background: #fff;
  border-radius: 4px;
  cursor: pointer;
  &.disabled {
    background: #e8ecef;
    cursor: no-drop;
  }
  .dropzone-input-ui {
    text-align: center;
    .border-dash {
      border: 1px dashed #e0e0e0;
      background: #fbfbfb;
      border-radius: 5px;
      padding: 30px 50px;
      i {
        color: #e0e0e0;
        font-size: 54px;
      }
      h3 {
        color: #bdbdbd;
      }
      &:hover {
        background: #f5efef;
        cursor: pointer;
      }
    }
    .dropzone-holder-image {
      position: relative;
      @media screen and (max-width: 480px) {
        img {
          width: 100%;
          height: auto;
          object-fit: contain;
        }
      }
      .dropzone-holder-label {
        position: absolute;
        padding: 40px;
        margin: auto;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        visibility: hidden;
        p {
          color: #fff;
        }
      }
      &:hover {
        .dropzone-holder-label {
          background: rgba(0, 0, 0, 0.41);
          visibility: unset;
        }
      }
    }
  }
  .dropzone-list-thumbnail {
    // padding: 0px 40px 0px 40px;
    .img-list-upload {
      height: 100px;
      width: 100%;
      object-fit: cover;
    }
    .close-icon {
      background: rgba(0, 0, 0, 0.31);
      position: absolute;
      right: -10px;
      text-align: center;
      height: 22px;
      width: 22px;
      border-radius: 100px;
      cursor: pointer !important;
      i {
        color: #fff;
      }
    }
  }
}

.upload-doc {
  width: intrinsic;
  width: -moz-max-content;
  width: -webkit-max-content;
  .dropzone-add {
    position: relative;
    z-index: 1;
    left: 50%;
    transform: translateX(-50%);
    height: 30px;
    width: 30px;
    margin-top: 5px;
  }
  .dropzone-folder {
    position: relative;
    margin-left: -30px;
  }
}

.dropzone-icon {
  width: 100%;
  .dropzone-icon-default {
    height: 45px;
    width: 45px;
    display: table;
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    border-radius: 5px;
    i {
      display: table-cell;
      vertical-align: middle;
      text-align: center;
      font-size: 12px;
      color: #e0e0e0;
    }
  }
  .dropzone-icon-mini-default {
    height: 30px;
    width: 30px;
    display: table;
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    border-radius: 5px;
    i {
      display: table-cell;
      vertical-align: middle;
      text-align: center;
      font-size: 12px;
      color: #e0e0e0;
    }
    img {
      display: table-cell;
      vertical-align: middle;
      margin: auto;
    }
  }
}

.text-required {
  color: #f7685b;
}
