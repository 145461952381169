.tab-dax {
  border-bottom: 1px solid #e0e0e0;
  .nav-item {
    a {
      color: #e0e0e0;
      font-size: 13px;
      font-weight: bold;
      &.active {
        border-width: 2px;
        border-color: #fff #fff #4e8ccb !important;
        color: #4e8ccb;
        a {
          border-width: 2px;
          border-color: #fff #fff #4e8ccb !important;
          color: #4e8ccb;
        }
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
}
