/* Tables */

.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
}
.table {
  margin-bottom: 0;

  thead {
    th {
      border-top: 0;
      border-bottom-width: 1px;
      font-family: $type-1;
      font-weight: 600;
      color: #254153;
      border-bottom: 1.3px solid #4f4f4f;
      border-bottom-color: $border-color;

      i {
        margin-left: 0.325rem;
      }
    }
  }

  td,
  th {
    vertical-align: middle;
    font-size: 12px;
    font-weight: normal;
    color: #707683;
    line-height: 1;
    white-space: nowrap;

    &.row-no {
      width: 1%;
    }

    &.row-action {
      width: 8%;
    }

    img {
      @extend .img-xs;
      // border-radius: 100%;
    }

    .table-key {
      cursor: pointer;
      font-weight: 500;
      color: #254153;
    }

    .table-link {
      cursor: pointer;
      color: #00b2ff !important;
      font-size: 12px;
    }

    .badge {
      margin-bottom: 0;
    }

    .form-check,
    .form-radio {
      margin-top: 0;
      margin-bottom: -0px;
    }
  }

  th {
    padding: 15px 20px;
  }

  td {
    padding: 4px 20px;
  }

  &.table-borderless {
    border: none;

    td,
    th,
    tr {
      border: none;
    }
  }

  &.table-striped {
    tbody {
      tr {
        &:nth-of-type(even) {
          background-color: #fbfbfb;
        }
      }
    }
  }

  &.table-bordered {
    thead {
      border: 1px solid $border-color;
      border-bottom: none;

      tr {
        th {
          font-size: 12px;
          // border-left: none;
          // border-right: none;
        }
      }
    }

    tbody {
      tr {
        td {
        }
      }
    }
  }

  input[type='checkbox'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: inline-block;
    width: 1.8em;
    height: 1.8em;
    border: 1px solid #e0e0e0;
    border-radius: 3px;
    outline: none;
    vertical-align: middle;
    cursor: pointer;
    position: relative;
  }

  input[type='checkbox']:checked {
    background-color: #fff;
    border: 1px solid $sidely-blue;

    &::after {
      content: '';
      position: absolute;
      left: 4px;
      top: 8px;
      background: $sidely-blue;
      width: 2px;
      height: 2px;
      box-shadow: 2px 0 0 $sidely-blue, 4px 0 0 $sidely-blue,
        4px -2px 0 $sidely-blue, 4px -4px 0 $sidely-blue,
        4px -6px 0 $sidely-blue, 4px -8px 0 $sidely-blue;
      transform: rotate(45deg);
    }
  }

  .text-blueish {
    background-color: $sidely-blue-blur;
    color: $sidely-blue !important;

    td,
    a,
    div a,
    i {
      color: $sidely-blue !important;
    }
  }

  tbody {
    i {
      color: #c8cbdc;
    }
  }
}

.btn-drop-all {
  padding: 0;
  background: #f4f3fa;
  width: 30px;
  height: 30px;
  border-radius: 50%;

  &:hover {
    background: #544397;
    color: #fff;
  }
}

.btn-outline-filter {
  border: 1px solid #f2f2f2;
  color: #828282;
  border-radius: 20px;
  font-size: 11px;
  font-weight: 400 !important;

  &:hover {
    background: #7f3f98;
    color: #fff;
  }

  &.active {
    border: 1px solid #7f3f98;
    color: #7f3f98;

    &:hover {
      background: #7f3f98;
      color: #fff;
    }
  }
}

.max-text {
  line-height: 16px;
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  /* Styles */
  .table-responsive {
    max-height: 500px;
  }
}

.cell-edit-ui {
  padding: 0;
  border: 0;
  color: #7f3f98;
}

.full-width {
  width: 100%;
}

// input[type='checkbox'] {
// 	-webkit-appearance: none;
// 	-moz-appearance: none;
// 	appearance: none;
// 	display: inline-block;
// 	width: 1.8em;
// 	height: 1.8em;
// 	border: 1px solid #e0e0e0;
// 	border-radius: 3px;
// 	outline: none;
// 	vertical-align: middle;
// 	cursor: pointer;
// 	position: relative;
// }

// input[type='checkbox']:checked {
// 	background-color: #fff;
// 	border: 1px solid #544397;
// 	&::after {
// 		content: '';
// 		position: absolute;
// 		left: 4px;
// 		top: 8px;
// 		background: #544397;
// 		width: 2px;
// 		height: 2px;
// 		box-shadow: 2px 0 0 #544397, 4px 0 0 #544397, 4px -2px 0 #544397, 4px -4px 0 #544397, 4px -6px 0 #544397,
// 			4px -8px 0 #544397;
// 		transform: rotate(45deg);
// 	}
// }

// .table-header-fixed {
//   // border-collapse: collapse;
//   // table-layout: fixed;
//   // td,
//   // th {
//   //   padding: 19px 30px !important;
//   // }
//   // td {
//   //   width: inherit;
//   // }
//   tbody {
//     display: table-row-group;
//     height: 40vh;
//     overflow: auto;
//   }
//   thead {
//     display: table-header-group;
//   }
//   thead {
//     // width: calc(100% - 1em);
//   }
// }
// .table-header-fixed {
//   position: relative;
// }

.table-bg {
  max-width: 100% !important;
  background: white;
  padding: 10px;
}

.page-background {
  background: #ffffff;
  border: 1px solid $border-color;
  box-sizing: border-box;
}

.bg-table {
  background: none;
}

.table-dashboard {
  .table-responsive {
    margin: 0;
    display: block;
    overflow-y: auto;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 10px;
      background: #f3f3f3 !important;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $sidely-blue !important;
      border-radius: 5px;
    }

    scrollbar-color: $sidely-blue #f9f9fb;
  }

  .react-bootstrap-table {
    .table {
      thead {
        display: none;
      }

      tbody {
        tr {
          &:first-child {
            td {
              border: none;
            }
          }

          // td {
          //   &:first-child {
          //     border: none;
          //   }

          //   // padding-top: 12px;
          //   // padding-bottom: 12px;
          // }
        }
      }
    }
  }
}
