.info {
	.text-title {
		color: #bdbdbd;
		font-size: 14px;
		line-height: 17px;
		padding: 5px 0;
	}
	.list-info {
		padding: 5px 0;
		display: flex;
		flex-wrap: wrap;
		.space {
			margin-left: -12px;
		}
		.text-key {
			font-size: 12px;
			color: #4f4f4f;
			font-weight: 600;
		}
		.text-value {
			font-size: 12px;
			color: #4f4f4f;
		}
		.img-build {
			width: 254px;
			border-radius: 5px;
		}
	}
}

.profile-detail {
	.avatar-detail-profile {
		width: 100px;
		height: 100px;
		border-radius: 50px;
	}
	.avatar-detail-profile-mitra {
		width: 100px;
		height: 100px;
		border-radius: 50px;
		background-color: #544397;
	}
	.text-key {
		font-size: 7px;
		color: #4f4f4f;
		line-height: 9px;
		margin-bottom: 8px;
		margin-top: 8px;
	}
	.text-value {
		font-size: 14px;
		color: #4f4f4f;
		line-height: 17px;
	}
	.avatar-detail-product {
		width: 150px;
		height: 150px;
	}
}

.header-order-detail {
	z-index: 999;
}

.order-detail {
	.avatar-detail-profile {
		width: 100px;
		height: 100px;
		border-radius: 50px;
	}
	.text-key {
		font-size: 7px;
		color: #4f4f4f;
		line-height: 9px;
		margin-bottom: 8px;
		margin-top: 8px;
	}
	.text-value {
		font-size: 12px;
		color: #4f4f4f;
		line-height: 14px;
	}
	.avatar-detail-product {
		width: 150px;
		height: 150px;
	}
}

.product-table {
	margin-bottom: 30px;
	font-weight: bold;
	position: relative;
	.count {
		position: absolute;
		border: 1px solid #f2f2f2;
		background: #fff;
		font-weight: normal;
		width: 30px;
		height: 20px;
		text-align: center;
		padding-top: 3px;
		border-radius: 5px;
		box-sizing: border-box;
	}
	.count-custom {
		border: 1px solid #f2f2f2;
		background: #fff;
		font-weight: normal;
		width: 30px;
		height: 20px;
		text-align: center;
		padding: 3px;
		border-radius: 5px;
		box-sizing: border-box;
	}
	tbody {
		tr {
			&:first-child {
				th,
				td {
					border-top: unset;
				}
			}
		}
	}
	tfoot {
		tr {
			th,
			td {
				border-bottom: 1px solid #dedede;
			}
		}
	}
}

.line-detail {
	width: 100%;
	height: 1px;
	background: #e0e0e0;
	margin: 20px 10px;
}

.box-avatar-detail {
	width: 369px;
	height: 369px;
	.avatar-detail {
		width: 200px;
		height: 300px;
		border-radius: 5px;
	}
}

.subtitle-detail {
	margin: 0px 10px;
	color: #7f3f98;
}

.box-small {
	width: 35px;
	padding: 5px;
	border: 1px solid #e0e0e0;
	border-radius: 5px;
	margin-top: 10px;
	color: #e0e0e0;
	text-align: center;
}

.size-plus-icon {
	font-size: 12px;
}

.border-purple {
	border: 1px solid #7f3f98;
}

.mute-color {
	color: #bdbdbd !important;
}

.ant-modal-body {
	padding: 31px !important;
}

.text-initial-detail {
	font-size: 24px;
	color: #ffffff;
	font-weight: bold;
	padding-top: 30px;
	text-align: center;
}

.box-input-role {
	height: 55px;
	padding: 10px 5px;
}

.btn-ml {
	margin-left: 5px;
}

.object-contain {
	object-fit: contain !important;
}

.img-border {
	object-fit: contain;
	border: 1px solid #f2f2f2;
	border-radius: 5px;
}

.imgCustom {
	object-fit: contain;
	border: 1px solid #f2f2f2;
}
