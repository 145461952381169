.modal-builder {
  background: rgba(0, 0, 0, 0.3);
}

.section-builder {
  .collapse-header {
    button {
      padding-bottom: 0;
    }
  }
}

.text {
  &-12 {
    font-size: 12px;
  }
  &-14 {
    font-size: 14px;
  }
}

.react-form-builder-form {
  position: relative;

  .form-group {
    display: flex;
    justify-content: space-between;
    margin: 20px 40px;

    .control-label {
      margin-top: auto;
      margin-bottom: auto;
      width: 20%;
      text-align: end;
    }

    .form-control {
      margin-top: auto;
      margin-bottom: auto;
      width: 77%;
    }

    .form-upload-control {
      margin-top: auto;
      margin-bottom: auto;
      width: 77%;
    }

    &.checkbox {
      justify-content: normal;
      .form-control {
        width: intrinsic;
        width: -moz-max-content;
        width: -webkit-max-content;
        margin-left: 3%;
      }
    }

    .image-placeholder {
      display: flex;
      width: 100px;
      height: 100px;
      border: 1px solid black;
      border-radius: 5px;
      margin-left: 12%;
    }

    .image-title {
      margin-left: 12%;
      margin-bottom: 0;
      font-weight: 600;
    }
  }

  .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 9999;
  }

  .header-text {
    padding: 10px 0 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  // .dropdown-list {
  // }
}

.img-fit-cs {
  img {
    height: 200px;
    width: 200px;
    object-fit: contain;
    border: 1px solid #ccc;
  }
}

.field-camera-cs {
  .img-preview-cs {
    width: 100px !important;
    height: 100px;
    object-fit: contain;
  }
}
